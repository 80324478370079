export default class FileQuote {
  excceded = false
  quota = 0
  unitofMeasurement = 'Bytes'
  usedQuota = 0
  usedQuotaPercentage = 0
  show = false

  loadData (data) {
    if (data) {
      this.excceded = data.excceded
      this.quota = data.quota
      this.unitofMeasurement = data.unitofMeasurement
      this.usedQuota = data.usedQuota
      this.usedQuotaPercentage = data.usedQuotaPercentage
      this.show = true
    }
  }

  reset () {
    this.excceded = false
    this.quota = 0
    this.unitofMeasurement = 'Bytes'
    this.usedQuota = 0
    this.usedQuotaPercentage = 0
  }
}
