import UsersUrlsService from '@/services/users-urls.service'
import EventBus from '@/util/EventBus'
import getEnv from '@/util/env'

export default {
  name: 'UrlsDialog',
  components: { },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default: () => ({
        name: '',
        url: '',
        previewTitle: '',
        previewDescription: '',
        previewUrl: '',
      }),
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
  },
  data: function () {
    return {
      dataStoreUrls: {},
      show: false,
      loading: true,
      options: {},
      content: [],
      defaultItem: {
        name: '',
        url: '',
        previewTitle: '',
        previewDescription: '',
        previewUrl: '',
      },
      data: [],
      ready: true,
      helpText: this.$t('<p>Al crear una nueva url de seguimiento generamos un enlace corto para que lo puedas incluir desde tu campaña. Este enlace incluirá una vista previa de la imagen seleccionada.</p><p>Además, de forma automática agregaremos a cada URL un identificador único para que puedas analizar con detalle la interacción la interacción de cada usuario con tu mensaje.</p>'),
    }
  },
  computed: {
    formTitle () {
      return !this.editedItem?.id ? this.$t('Nueva URL') : this.$t('Editar Url')
    },
    isValidUrl () {
      return this.editedItem.previewUrl?.length && this.isValidHttpUrl(this.editedItem.previewUrl)
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  mounted () {
    EventBus.$on('multimediaSelected', (url) => {
      this.editedItem.previewUrl = getEnv('VUE_APP_ASSETS_URL') + url
    })
  },

  methods: {
    selectImage () {
      EventBus.$emit('showMultimedia', 'imagenes', false)
    },
    removeImage () {
      this.editedItem.previewUrl = ''
    },
    close () {
      this.editedItem = Object.assign(this.editedItem, this.defaultItem)
      this.$emit('close')
    },
    save () {
      if (!this.editedItem?.id) {
        this.$emit('loading', true)
        UsersUrlsService.postDataForm(this.editedItem)
          .then(
            (response) => {
              this.$emit('update-data')
            },
            () => {},
          )
          .finally(() => {
            this.$emit('loading', false)
            this.close()
          })
      } else {
        this.$emit('loading', true)
        UsersUrlsService.updateDataForm(this.editedItem)
          .then(
            (response) => {
              this.$emit('update-data')
            },
            () => { },
          )
          .finally(() => {
            this.$emit('loading', false)
            this.close()
          })
      }
    },
    isValidHttpUrl (str) {
      if (!str.trim().length) {
        return false
      }

      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
  },
}
