import { MenuOption } from '@/models/voice/MenuOption'

export default {
  props: ['currentKey', 'availableKeys', 'menuOption', 'menu', 'phonePreview'],
  name: 'Key',
  data: function () {
    return {
    }
  },
  methods: {
    setKey (key) {
      this.$emit('set-key', key)
    },
    getMenuOptionClassByKey (key) {
      const menuOptions = this.menu.menuOptions.filter(function (menuOption) {
        return key === menuOption.key
      })

      if (menuOptions.length > 0) {
        const menuOption = menuOptions[0]
        return MenuOption.getIconClass(menuOption.typeId)
      }
      return ''
    },
  },
  created () {

  },
  mounted () { },
}

