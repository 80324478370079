
export default {
  name: 'Loading',
  props: {
    toggle: { type: Boolean, require: true },
  },
  data () {
    return {
      show: false,
      percentage: 0,
      interval: 0,
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    toggle: {
      handler: function () {
        if (this.toggle === true) {
          this.percentage = 0
          this.show = true
          this.loaderInterval()
        } else {
          clearInterval(this.interval)
          this.finishInterval()
        }
      },
      deep: true,
    },
  },
  methods: {
    loaderInterval (timeout = 300) {
      this.interval = setInterval(() => {
        if (this.percentage === 99) {
          clearInterval(this.interval)
          return
        }
        this.percentage += 1
      }, timeout)
    },
    finishInterval (timeout = 10) {
      this.percentage = 100
      setTimeout(() => {
        this.show = false
      }, 10)
      // this.interval = setInterval(() => {
      //   if (this.percentage === 100) {
      //     clearInterval(this.interval)
      //     setTimeout(() => {
      //       this.show = false
      //     }, 100)
      //     return
      //   }
      //   this.percentage += 1
      // }, timeout)
    },
  },
}
