export class Permissions {
  vueContext = null
  constructor (vueContext) {
    this.vueContext = vueContext
  }

  inject () {
    this.setPermissionDirectives()
    this.setPermissionHelpers()
  }

  checkHasPermissionsFromRouteMeta (toRoute) {
    const routePermissions = toRoute.meta.permissions
    console.log(routePermissions)
    if (routePermissions) {
      return this.hasPermission(routePermissions.permission)
    }
    return true
  }

  hasRole (roles) {
    const user = JSON.parse(localStorage.getItem('user'))
    const userRoles = user.roles ? user.roles : []
    const hasCommonElement = userRoles.some(item => roles.includes(item))
    return hasCommonElement
  }

  hasPermission (permissions) {
    const user = JSON.parse(localStorage.getItem('user'))
    const userPermissions = user.permissions ? user.permissions : []
    return userPermissions.includes(permissions)
  }

  setPermissionHelpers () {
    this.vueContext.prototype.$permissions = {
      hasRole: this.hasRole,
      hasPermission: this.hasPermission,
    }
  }

  setPermissionDirectives () {
    const that = this
    this.vueContext.directive('permission', {
      inserted: function (el, binding) {
        const user = JSON.parse(localStorage.getItem('user'))
        const userPermissions = user.permissions ? user.permissions : []
        const expression = binding.value
        const hasPermission = that.evaluatePermissions(expression, userPermissions)
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      },
    })

    this.vueContext.directive('role', {
      inserted: function (el, binding) {
        const user = JSON.parse(localStorage.getItem('user'))
        const userRoles = user.roles ? user.roles : []
        const expression = binding.value
        const hasPermission = that.evaluatePermissions(expression, userRoles)
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      },
    })
  }

  evaluatePermissions (expression, userPermissions) {
    const tokens = expression.match(/(?:\w|-)+|&&|\|\||[()]/g).map(token => {
      if (userPermissions.includes(token.trim())) {
        return 'true'
      } else if (/^(?:\w|-)+$/.test(token)) {
        return 'false'
      } else {
        return token
      }
    })

    const parsedExpression = tokens.join(' ').trim()
    try {
      // eslint-disable-next-line no-new-func
      return new Function(`return ${parsedExpression}`)()
    } catch (e) {
      console.error('Error al evaluar la expresión de permisos:', e)
      return false
    }
  }
}
