import { Service } from './service'

const PATH = 'account'

class AccountService extends Service {
    getProfile () {
        return this.get(PATH + '/profile')
    }

    getAdditionalCompanies () {
        return this.get(PATH + '/additional-companies')
    }

    getLogo () {
        return this.get(PATH + '/logo')
    }

    updateProfile (data) {
        return this.post(PATH + '/update-profile', data)
    }

    getBilling () {
        return this.get(PATH + '/billing')
    }

    updateBilling (data, userId) {
        return this.post(PATH + '/billing/update/' + userId, data)
    }

    updateApiKey (params) {
        return this.post(PATH + '/update-api-key', params)
    }

    getNewApiPassword () {
        return this.get(PATH + '/new-api-password')
    }

    updateSmppPassword (params) {
        return this.post(PATH + '/update-smpp-password', params)
    }

    getNewSmppPassword () {
        return this.get(PATH + '/new-smpp-password')
    }

    sendLink (params) {
        return this.post('password/email', params)
    }

    getEmails () {
        return this.get(PATH + '/user-emails')
    }

    getPlan () {
        return this.get(PATH + '/plan')
    }

    getIps () {
        return this.get(PATH + '/ips')
    }

    saveIp (params) {
        return this.post(PATH + '/ips', params)
    }

    removeIp (params) {
        return this.post(PATH + '/ips/delete', params)
    }

    getBank () {
        return this.get(PATH + '/bank')
    }

    saveBank (userId, data) {
        return this.post(PATH + '/bank/update/' + userId, data)
    }

    getBalance () {
        return this.get(PATH + '/balance')
    }

    saveBalanceAlert (data) {
        return this.post(PATH + '/balance/update', data)
    }

    uploadLogo (data) {
        return this.post(PATH + '/logo/update', data)
    }

    deleteLogo () {
        return this.post(PATH + '/logo/delete')
    }

    getMainStats () {
        return this.get(PATH + '/main-stats')
    }

    getUserBalance () {
        return this.get(PATH + '/user/balance')
    }

    getUserLanguage () {
        return this.get(PATH + '/user/language')
    }

    setUserLanguage (params = {}) {
        return this.post(PATH + '/user/language', params)
    }

    getUserCountry () {
        return this.get(PATH + '/user/country')
    }

    getAutoLoginToken () {
        return this.get('auto-login-token')
    }
}

export default new AccountService()
