import Vue from 'vue'
import VueInternalization from 'vue-i18n'
import { messages } from './locales/messages'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import DashboardPlugin from './plugins/dashboard-plugin'
import authService from './services/auth.service'
import { RatingAppLauncher } from './classes/RatingAppLauncher'
import Vuelidate from 'vuelidate'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ValidationRules } from './util/ValidationRules'
import { ValidatorLocalize } from './util/ValidatorLocalize'
import { formatDate, formatDateYearEnd, formatDateLocal, lastModified, currency, currencySymbol, formatBytes, formatMoneyCurrentCurrency, shortChars, shortDecimal, truncate, capitalize, reduceDecimals } from '@/filters/filters'
import { ErrorMessages } from './util/ErrorMessages.js'
import VueGtag from 'vue-gtag'
import { Dates } from './util/Dates'
import { Permissions } from './util/Permissions'

const validationRules = new ValidationRules()
validationRules.extendRules()
ValidatorLocalize.setLanguage('es')

Vue.filter('date', formatDate)
Vue.filter('dateYearEnd', formatDateYearEnd)
Vue.filter('dateLocal', formatDateLocal)
Vue.filter('lastModified', lastModified)
Vue.filter('currency', currency)
Vue.filter('currencySymbol', currencySymbol)
Vue.filter('formatMoneyCurrentCurrency', formatMoneyCurrentCurrency)
Vue.filter('shortChars', shortChars)
Vue.filter('shortDecimal', shortDecimal)
Vue.filter('formatBytes', formatBytes)
Vue.filter('truncate', truncate)
Vue.filter('capitalize', capitalize)
Vue.filter('reduceDecimals', reduceDecimals)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const permissions = new Permissions(Vue)
permissions.inject()

Vue.config.productionTip = false

// plugin setup
Vue.use(DashboardPlugin)

// Vuelidate
Vue.use(Vuelidate)

// vueFilterPrettyBytes
Vue.use(vueFilterPrettyBytes)

authService.removeUserIfExipred().then(() => {
  // eslint-disable-next-line complexity
  router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/web-chat/chat']

    if (publicPages.includes(to.path)) {
      return next()
    }

    const loginRequired = publicPages.includes(to.path)
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = localStorage.getItem('user')

    // trying to access a restricted page + not logged in
    // redirect to login page
    const restrictedPages = ['share', '/password/reset']
    const includedRestricted = restrictedPages.filter(path => to.path.includes(path))
    const isRestricted = includedRestricted.length > 0
    if (isRestricted) {
      next()
    }
    if (authRequired && !loggedIn && !isRestricted) {
      next('/login')
    } else if (loginRequired && loggedIn) {
      next('/home')
    } else {
      if (!permissions.checkHasPermissionsFromRouteMeta(to)) {
        next('/home')
      } else {
        next()
      }
      next()
    }
    if (to.path === '/home') {
      RatingAppLauncher.checkRateLauncher()
    }
  })

  Vue.use(VueInternalization)

  const i18n = new VueInternalization({
    locale: localStorage.getItem('language'), // set locale
    fallbackLocale: 'es',
    messages, // set locale messages
  })

  Vue.prototype.$t = function (...args) {
    const result = this.$i18n.t.apply(this.$i18n, args)
    if (result === '') {
      return this.$i18n.t(args[0], this.$i18n.fallbackLocale, args[2])
    }
    return result
  }

  Vue.prototype.$tc = function (...args) {
    const result = this.$i18n.tc.apply(this.$i18n, args)
    if (result === '') {
      return this.$i18n.t(args[0], args[1], this.$i18n.fallbackLocale, args[3])
    }
    return result
  }

  Vue.prototype.$datesUtils = Dates

  // Global variables
  Vue.prototype.$errorMessages = new ErrorMessages(i18n)
  Vue.prototype.$isSectionsBlockeds = function (section) {
    const sectionsBlockeds = JSON.parse(localStorage.getItem('sectionsBlockeds')) || []
    if (sectionsBlockeds.includes(section)) {
      return true
    }
    return false
  }
  Vue.prototype.$smsColor = '#CF3721'
  Vue.prototype.$emailColor = '#31A9B8'
  Vue.prototype.$voiceColor = '#F5BE41'
  Vue.prototype.$whatsappColor = '#21A252'

  Vue.use(VueGtag, {
    config: { id: 'G-1E5S5672BF' },
  })

  new Vue({
    router,
    vuetify,
    store,
    i18n,
    devtool: 'source-map',
    created: function () {
      this.smsColor = 'test'
    },
    render: (h) => h(App),
  }).$mount('#app')
})
