import MultimediaService from '@/services/multimedia.service.js'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'ListDirectory',
  props: {
    isRootDirectory: { type: Boolean, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaDirectory: { type: Object, require: true },
    multimediaPreview: { type: Object, required: false },
    fileQuota: { type: Object, require: true },
    currentDirectory: { type: Object, require: true },
    openPath: { type: Boolean, require: false, default: false },
    moveRootPath: { type: String, require: false, default: '' },
    openGlobal: { type: Boolean, require: true },
    sectionDirectories: { type: Array, require: true },
  },
  data () {
    return {
      isShown: false,
      titleSection: false,
    }
  },
  methods: {
    selectRootDirectory (currentPath) {
      const rootPath = currentPath.split('/')[0]
      if (rootPath === currentPath && !this.openPath) {
        EventBus.$emit('multimediaManager::searchClear')
        this.getMultimediaDirectories(currentPath)
      } else {
        EventBus.$emit('multimediaManager::searchClear')
        this.selectCurrentDirectory(currentPath)
      }
    },
    // eslint-disable-next-line complexity
    selectCurrentDirectory (currentPath) {
      const rootPath = currentPath.split('/')[0]
      if (this.openPath) {
        const elemen = this.$refs.directoryItem
        this.removeDirectoryitemActive()
        this.addDirectoryitemActive(elemen)
        this.multimediaDirectories.move.newPath = currentPath
      } else if (
        (!this.openPath) &&
        (!this.openGlobal) &&
        (this.sectionDirectories.includes(rootPath))
      ) {
        const split = currentPath.split('/')
        let directory = this.multimediaDirectories.directories[split[0]]
        for (let i = 1; i < split.length; i++) {
          directory = directory.directories[split[i]]
        }
        this.multimediaPreview.reset()
        this.currentDirectory.loadData(directory)
      } else if (
          (this.openGlobal) ||
          (!this.openGlobal && this.currentRootDirectory() === rootPath)
        ) {
        if (currentPath === 'papelera') {
          this.multimediaDirectories.hideFixedMultimedia()
        }
        const split = currentPath.split('/')
        let directory = this.multimediaDirectories.directories[split[0]]
        for (let i = 1; i < split.length; i++) {
          directory = directory.directories[split[i]]
        }
        this.multimediaPreview.reset()
        this.currentDirectory.loadData(directory)
      }
    },
    getMultimediaDirectories (currentRootDirectory) {
      MultimediaService.getAll({ path: currentRootDirectory }).then(
        (response) => {
          this.multimediaDirectories.loadData(response.multimediaDirectories)
          this.fileQuota.loadData(response.fileQuota)
          this.selectCurrentDirectory(currentRootDirectory)
        },
        () => { },
      )
    },
    setTitleSection (multimediaDirectory) {
      if (this.openPath === false) {
        const rootPath = multimediaDirectory.path
        if (rootPath === 'landings') {
          this.titleSection = this.$t('Plantillas:')
          return true
        }
        if (rootPath === 'imagenes') {
          this.titleSection = this.$t('Archivos:')
          return true
        }
        if (rootPath === 'papelera') {
          this.titleSection = ''
          return true
        }
      }

      return false
    },
    setIsFolderIsOpen (multimediaDirectory, currentDirectory) {
      if (this.openPath === false) {
        if (this.isShown) {
          return 'fas fa-angle-down'
        } else {
          return 'fas fa-angle-right'
        }
      }

      const setRecursiveFolder = this.setIsRecursiveFolderIsOpen(multimediaDirectory, currentDirectory)
      if (setRecursiveFolder) {
        return 'fas fa-angle-down'
      }

      return 'fas fa-angle-right'
    },
    setIsRecursiveFolderIsOpen (multimediaDirectory, currentDirectory) {
      const multimediaDirectories = multimediaDirectory.path.split('/')
      const currentDirectories = currentDirectory.path.split('/')

      if (currentDirectories[0] === 'papelera') {
        if (multimediaDirectory.path === this.moveRootPath) {
          this.isShown = true
          return true
        }
      }

      for (const currentDirectorie of currentDirectories) {
        if (currentDirectorie === multimediaDirectories[multimediaDirectories.length - 1]) {
          this.isShown = true
          return true
        }
      }
      return false
    },
    selectedDirectoryIsFile (multimediaDirectory, currentDirectory) {
      if (this.openPath === false) {
        return true
      }

      if (this.openPath === true) {
        return this.openRootDirectory(currentDirectory, multimediaDirectory)
      }

      return false
    },
    openRootDirectory (currentDirectory, multimediaDirectory) {
      const currentPaths = currentDirectory.path.split('/')
      const multiemdiaPaths = multimediaDirectory.path.split('/')
      if (currentPaths[0] === 'papelera') {
        if (multiemdiaPaths.includes(this.moveRootPath)) {
          setTimeout(() => {
            this.selectCurrentDirectory(multimediaDirectory.path)
          }, 500)
          return true
        }
        return false
      }

      for (const currentPath of currentPaths) {
        if (multiemdiaPaths.includes(currentPath)) {
          return true
        }
      }

      return false
    },
    toggleDirectory (currentPath) {
      const rootPath = currentPath.split('/')[0]
      if (this.openGlobal) {
        this.isShown = !this.isShown
      } else if (!this.openGlobal && this.currentRootDirectory() === rootPath) {
        this.isShown = !this.isShown
      }
    },
    addDirectoryitemActive (elemen) {
      elemen.classList.add('directory__item-active')
    },
    removeDirectoryitemActive () {
      const elements = document.querySelectorAll('.directory__item')
      elements.forEach(function (elemen) {
        elemen.classList.remove('directory__item-active')
      })
    },
    isAttachedFilesDirectory (dirname) {
      return dirname.toLowerCase() === 'adjuntos'
    },
    // eslint-disable-next-line complexity
    setDirectoryName (path) {
      const split = path.split('/')
      const name = split[split.length - 1]
      if (this.isRootDirectory) {
        if (path === 'whatsapp') {
          return this.$t('WhatsApp')
        }
        if (
          path === 'voz' ||
          path === 'audios' ||
          path === 'papelera' ||
          path === 'adjuntos' ||
          path === 'formularios' ||
          path === 'videos'
        ) {
          return this.$t(this.capitalizaice(name).replace(/-/g, ' '))
        }
        if (path === 'csv') {
          return name.toUpperCase()
        }
        if (path === 'imagenes') {
          return this.$t('Imágenes')
        }
        if (path === 'landings') {
          return 'Landing page'
        }
      }
      return name
    },
    rootDirectory () {
      return this.multimediaDirectory.path.split('/')[0]
    },
    currentRootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
    // eslint-disable-next-line complexity
    setIconRootDirectory (path) {
      if (this.isRootDirectory) {
        if (path === 'imagenes') {
          return ['fas fa-image']
        }
        if (path === 'csv') {
          return ['fas fa-file-csv mr-1']
        }
        if (path === 'audios') {
          return ['fas fa-music']
        }
        if (path === 'voz') {
          return ['fas fa-phone-alt']
        }
        if (path === 'landings') {
          return ['fas fa-file mr-1']
        }
        if (path === 'formularios') {
          return ['fas fa-check-square']
        }
        if (path === 'papelera') {
          return ['fas fa-trash mr-1']
        }
        if (path === 'adjuntos') {
          return ['fas fa-paperclip mr-1']
        }
        if (path === 'videos') {
          return ['fas fa-video']
        }
        if (path === 'whatsapp') {
          return ['fab fa-whatsapp-square']
        }
      }
    },
    capitalizaice (name) {
      return (
        name.charAt(0).toLocaleUpperCase() + name.substr(1).toLowerCase()
      )
    },
    rootDirectoryActive () {
      if (this.multimediaDirectory.path === this.currentDirectory.path) {
        return true
      }

      if (this.multimediaDirectory.path === this.currentDirectory.initialPath) {
        return true
      }

      return false
    },
    rootDirectoryDisabled () {
      const rootPath = this.multimediaDirectory.path.split('/')[0]
      const currentRootPath = this.currentDirectory.path.split('/')[0]
      if (this.multimediaDirectory.path === this.currentDirectory.path) {
        return false
      }

      if (this.multimediaDirectory.path === this.currentDirectory.initialPath) {
        return false
      }

      if (rootPath === currentRootPath) {
        return false
      }

      if (!this.openGlobal && !this.openPath) {
        return true
      }

      return false
    },
  },
  mounted () {
    EventBus.$on('ListDirectory:selectRootDirectory', this.selectRootDirectory)
  },
}
