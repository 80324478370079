import AccountService from '@/services/account.service.js'

// initial state
const state = () => ({
  amount: 0,
  userPlan: {
    contact: 0,
    mailing: 0,
    push_app: 0,
  },
  currencySymbol: 'EUR',
})

// getters
const getters = {}

// actions
const actions = {
  async getBalance ({ commit }) {
    try {
      const response = await AccountService.getUserBalance()
      if (response.balance !== undefined) {
        commit('setAmount', response.balance)
        commit('setUserPlan', response.userPlan)
        commit('setCurrencySymbol', JSON.parse(localStorage.getItem('user')).currencyCode)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

// mutations
const mutations = {
  setAmount (state, amount) {
    state.amount = amount
  },
  setUserPlan (state, userPlan) {
    state.userPlan = userPlan
  },
  setCurrencySymbol (state, currencySymbol) {
    state.currencySymbol = currencySymbol
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
