import { Service } from './service'

const PATH = 'developers/'

class DeveloperService extends Service {
  getPluginModulesAndIntegrations (params = {}) {
    return this.get(PATH + 'plugin-modules-integrations', params)
  }

  connectServiceHubspot (data = {}) {
    return this.post('oauth/connect/hubspot', data)
  }

  disconnectService (data = {}) {
    return this.post('oauth/disconnect', data)
  }

  getInlineIntegrationApiKey (params) {
    return this.post(PATH + 'integrations/inline/get-api-key', params)
  }

  connectInlineIntegrationApiKey (params) {
    return this.post(PATH + 'integrations/inline/connect', params)
  }

  disconnectInlineIntegrationApiKey (params) {
    return this.post(PATH + 'integrations/inline/disconnect', params)
  }
}

export default new DeveloperService()
