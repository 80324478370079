export default class MultimediaPreview {
  csv = null
  date = ''
  extension = ''
  fileId = null
  fileType = ''
  name = ''
  nameTemplate = ''
  path = ''
  size = null
  type = ''
  url = ''
  preview = ''
  show = false
  selectFile = false
  source = 'library'

  loadData (data) {
    if (data) {
      this.csv = data.csv
      this.date = data.date
      this.extension = data.extension
      this.fileId = data.fileId
      this.fileType = data.fileType
      this.name = data.name
      this.nameTemplate = data.nameTemplate
      this.path = data.path
      this.size = data.size
      this.type = data.type
      this.preview = data.preview
      this.url = data.url
      this.selectFile = true
      this.show = true
    }
  }

  reset () {
    this.csv = null
    this.date = ''
    this.extension = ''
    this.fileId = null
    this.fileType = ''
    this.name = ''
    this.nameTemplate = ''
    this.path = ''
    this.size = null
    this.type = ''
    this.url = ''
    this.preview = ''
    this.show = false
    this.selectFile = false
    this.source = 'library'
  }
}
