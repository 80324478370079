import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'DeletedTrash',
  components: {},
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      show: false,
      loading: false,
    }
  },
  methods: {
    toogle () {
      this.show = !this.show
    },
    deletedTrash () {
      this.loading = true

      MultimediaService.deletedTrash().then(
        (response) => {
          this.toogle()
          this.multimediaPreview.reset()
          this.multimediaDirectories.loadData(response.multimediaDirectories)
          const split = this.currentDirectory.path.split('/')
          let directory = this.multimediaDirectories.directories[split[0]]
          for (let i = 1; i < split.length; i++) {
            directory = directory.directories[split[i]]
          }
          this.currentDirectory.loadData(directory)
          this.fileQuota.loadData(response.fileQuota)
          EventBus.$emit('showAlert', 'success', this.$t(response.code))
        },
        (error) => {
          MultimediaService.errorResponse(
            error.response.status,
            this.$t('Error al intentar eliminar papelera'),
            this.$t(error.response.data.code),
          )
        },
      ).finally(() => {
        this.loading = false
      })
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
  },
  created () {
    EventBus.$on('deletedTrashMultimedia', () => {
      this.toogle()
    })
  },
}
