import { ChannelType } from '@/models/ChannelType'
import FormConfigurationService from '@/services/form-configuration.service.js'
import GroupService from '@/services/group.service.js'
import { Country } from '@/util/Country'
import EventBus from '@/util/EventBus'

export default {
    name: 'Senders',
    props: {
      canUseCustomSender: Boolean,
      campaign: Object,
      componentsData: Object,
      senderIn: [Object, String],
      disableComboBox: Boolean,
      isValidDlt: {
        type: Boolean,
        required: false,
        default: false,
      },
      justVerified: {
        type: Boolean,
        required: false,
        default: false,
      },
      notCanUseCustomSender: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: function () {
      return {
        REGEX_SENDER: /^([0-9A-Za-zÀ-ÿ_.&\-+ ]{1,11}|[0-9]{1,15})$/,
        sender: this.senderIn,
        search: '',
        formConfigurationFields: {},
        disableComboBoxIn: this.disableComboBox ? this.disableComboBox : false,
        Country: Country,
        noCustomSenderCountries: [],
        groupHasNotCustomSenderCountries: false,
        senderMaxLength: 15,
      }
    },
    computed: {
      senderText: {
        get: function () {
          if (this.sender === this.DEFAULT_SENDER.value) {
            return this.DEFAULT_SENDER.text
          }
          return this.sender
        },
        set: function (newValue) {
          if (newValue.text === this.DEFAULT_SENDER.text) {
            this.sender = this.DEFAULT_SENDER.value
          } else {
            this.sender = newValue.text
          }
        },
      },
      DEFAULT_SENDER: function () {
        return {
          text: this.$t('Automatico'),
          value: 'Test',
        }
      },
      hasTwoWaySenders: function () {
        return this.formConfigurationFields.twoWaySenders?.length ||
               this.formConfigurationFields.twoWayDedicatedSenders?.length
      },
      // eslint-disable-next-line complexity
      senders: function () {
        const senderList = []
        const that = this

        if (this.formConfigurationFields.verifiedSenders && this.canUseCustomSender) {
          this.formConfigurationFields.verifiedSenders.map(function (item, index) {
            senderList.push({
              type: that.$t('Verificado').toString(),
              text: item,
              value: item,
            })
          })
        }

        if (this.justVerified) {
          return senderList
        }

        if (!this.canUseCustomSender) {
          senderList.push({
            type: that.$t('Automatico'),
            text: that.DEFAULT_SENDER.text,
            value: that.DEFAULT_SENDER.value,
          })
        }
        if (this.formConfigurationFields.lastSenders && this.canUseCustomSender) {
          this.formConfigurationFields.lastSenders.map(function (item, index) {
            senderList.push({
              type: that.$t('Últimos remitentes').toString(),
              text: item,
              value: item,
            })
          })
        }
        if (this.formConfigurationFields.twoWaySenders) {
          this.formConfigurationFields.twoWaySenders.map(function (item, index) {
            senderList.push({
              type: 'TwoWay',
              text: item,
              value: item,
            })
          })
        }
        if (this.formConfigurationFields.twoWayDedicatedSenders) {
          this.formConfigurationFields.twoWayDedicatedSenders.map(function (item, index) {
            senderList.push({
              type: 'TwoWay ' + that.$t('Dedicado').toString(),
              text: item,
              value: item,
            })
          })
        }

        return senderList
      },
      campaignSender () {
        return this.campaign?.sendings[0].channel.sender
      },
      campaignGroups () {
        return this.campaign?.groups
      },
      selectedCountries () {
        const noCustomSender = !this.canUseCustomSender
          ? [Country.getFlag(this.campaign.countryIso)]
          : []
        return [...(new Set([...this.noCustomSenderCountries.map(c => {
          return Country.getFlag(c)
        }), ...noCustomSender]))]
      },
    },
    methods: {
      calculateMaxLength () {
        const numeric = /^[0-9]{1,15}$/.test(this.sender)
        if (!this.sender?.length || numeric) {
          this.senderMaxLength = 15
        } else {
          this.senderMaxLength = 11
        }
      },
      formatSender (value) {
        this.sender = value
        this.calculateMaxLength()

        if (!this.sender) {
          this.sender = ''
        }

        let tempSender = (typeof this.sender === 'object') ? this.sender.text : this.sender
        if (tempSender.length >= this.calculateMaxLength) {
          tempSender = tempSender.substring(0, this.calculateMaxLength)
        }
        this.sender = tempSender
        if (this.campaign) {
          if (this.sender === this.DEFAULT_SENDER.text) {
            this.campaign.sendings[0].channel.sender = this.DEFAULT_SENDER.value
          } else {
            this.campaign.sendings[0].channel.sender = this.sender
          }
        }
        this.$emit('updated-sender', this.sender)
        EventBus.$emit('validate-campaign-sender')
      },
    },
    watch: {
      canUseCustomSender (newValue) {
        if (newValue) {
          this.sender = ''
          this.campaign.sendings[0].channel.sender = ''
        }
      },
      campaignSender (newSender) {
        if (this.campaign) {
          if (newSender === this.DEFAULT_SENDER.value) {
            this.sender = this.DEFAULT_SENDER.text
          } else {
            this.sender = newSender
          }
        }
      },
      campaignGroups: function () {
        this.groupHasNotCustomSenderCountries = false
        if (this.campaign.recipientsSource === 'contacts' && this.campaign.groups.length) {
          GroupService.getAllContactsCountries({
            groups: this.campaign.groups,
          }).then(countries => {
            this.noCustomSenderCountries = countries.filter(c => {
              return ['BR', 'CO'].includes(c)
            })
            if (this.noCustomSenderCountries.length) {
              this.groupHasNotCustomSenderCountries = true
            } else {
              this.groupHasNotCustomSenderCountries = false
            }
          })
        } else {
          this.noCustomSenderCountries = []
        }
      },
    },
    created () {
      if (this.campaign.sendings[0].channel.type === ChannelType.RCS_ID) {
        FormConfigurationService.getRcsFields().then((data) => {
          this.formConfigurationFields = data.formConfigurationFields
          this.campaign.sendings[0].channel.verifiedSenders = data.formConfigurationFields.verifiedSenders
          this.formConfigurationFields.verifiedSenders = data.formConfigurationFields.verifiedSenders.map((verifiedSender) => {
            return verifiedSender.sender
          })
        })
      } else {
        FormConfigurationService.getFields().then((data) => {
          this.formConfigurationFields = data.formConfigurationFields
        })
      }
    },
}
