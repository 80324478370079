
import { Service } from '@/services/service'

const PATH = 'whatsapp/routes/'

class RoutesService extends Service {
  getCurrentUserRoutes () {
    return this.get(PATH + 'current-routes')
  }

  getCurrentUserRoute () {
    return this.get(PATH + 'current-route')
  }

  getCurrentUserAllRoutes () {
    return this.get(PATH + 'current-all-routes')
  }

  sendActivationCode () {
    return this.post(PATH + 'send-activation-code')
  }

  verifyActivationCode (params) {
    return this.post(PATH + 'verify-activation-code', params)
  }

  getUniqueWabas () {
    return this.get(PATH + 'unique-wabas')
  }

  getAllFacebookManagerId (params) {
    return this.get(PATH + 'all-facebook-manager-id', params)
  }

  getByFacebookManagerId (params) {
    return this.get(PATH + 'by-facebook-manager-id', params)
  }

  getWabaById (params) {
    return this.get(PATH + 'waba-by-id', params)
  }

  getPhonesByWabaId (params) {
    return this.get(PATH + 'by-waba-id', params)
  }

  getLine (params) {
    return this.get(PATH + 'by-phone-id', params)
  }

  getLineByMsisdn (params) {
    return this.get(PATH + 'by-msisdn', params)
  }

  disconnect () {
    return this.post(PATH + 'disconnect')
  }

  sendNewLine () {
    return this.post(PATH + 'send-new-line')
  }
}

export default new RoutesService()
