import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'DeletedSelectedMultipleItem',
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    close () {
      this.multimediaDirectories.selectedMultiple.show = false
    },
    deleted () {
      let path = this.currentDirectory.path
      if (this.currentDirectory.path === 'buscar') {
        path = this.multimediaDirectories.selectedMultiple.items[0].path
      }

      this.loading = true
      const params = {
        items: this.multimediaDirectories.selectedMultiple.items,
        path: path,
        newPath: 'papelera',
      }
      MultimediaService.moveMultipleFile(params).then(
        (response) => {
          this.close()

          if (this.currentDirectory.path === 'buscar') {
            EventBus.$emit('multimediaManager::search')
          }

          this.multimediaPreview.reset()
          this.multimediaDirectories.loadData(response.multimediaDirectories)
          const split = this.currentDirectory.path.split('/')
          let directory = this.multimediaDirectories.directories[split[0]]
          for (let i = 1; i < split.length; i++) {
            directory = directory.directories[split[i]]
          }
          this.currentDirectory.loadData(directory)

          EventBus.$emit('showAlert', 'success', this.$t(response.code))
        },
        (error) => {
          EventBus.$emit('showAlert', 'warning', error)
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
  },
}
