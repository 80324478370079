import moment from 'moment'
import momenttz from 'moment-timezone'

export class Dates {
    static daysOfWeek = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']
    // eslint-disable-next-line no-magic-numbers
    static daysOfWeekCount = 7;
    static getDayName (dayNumber) {
      return Dates.daysOfWeek[dayNumber - 1]
    }

    static dateFormatLocal (date, format = 'DD/MM/YYYY HH:mm') {
      const d = momenttz.tz(date, 'Europe/Madrid').format()
      return moment(d).local().format(format)
    }
}
