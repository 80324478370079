import AccountService from '@/services/account.service.js'

// initial state
const state = () => ({
  img: null,
})

// getters
const getters = {}

// actions
const actions = {
  async getLogo ({ commit }) {
    try {
      const response = await AccountService.getLogo()
      if (response) {
        commit('setLogo', `data:image/png;base64,${response}`)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async uploadLogo ({ commit }, data) {
    try {
      const response = await AccountService.uploadLogo(data)
      if (response.success) {
        commit('setLogo', `data:image/png;base64,${response.image}`)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async deleteLogo ({ commit }) {
    try {
      const response = await AccountService.deleteLogo()
      if (response.success) {
        commit('resetLogo')
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

// mutations
const mutations = {
  setLogo (state, logo) {
    state.img = logo
  },
  resetLogo (state) {
    state.img = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
