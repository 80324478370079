import multimediaItems from './enums/MultimediaItem.js'

export default class MultimediaDirectory {
  directories = {
    landings: multimediaItems,
    voz: multimediaItems,
    formularios: multimediaItems,
    whatsapp: multimediaItems,
    imagenes: multimediaItems,
    csv: multimediaItems,
    audios: multimediaItems,
    videos: multimediaItems,
    adjuntos: multimediaItems,
    papelera: multimediaItems,
  }

  createDirectory = {
    show: false,
    path: '',
  }

  deletedDirectory = {
    path: '',
    newPath: '',
    type: null,
    name: '',
    show: false,
  }

  deletedFile = {
    path: '',
    newPath: '',
    type: null,
    name: '',
    fileId: null,
    show: false,
  }

  move = {
    path: '',
    newPath: '',
    type: null,
    name: '',
    itemType: null,
    fileId: null,
    validate: {
      success: false,
      message: '',
    },
    show: false,
  }

  rename = {
    path: '',
    newPath: '',
    type: null,
    fileName: '',
    name: '',
    newName: '',
    itemType: null,
    fileId: null,
    show: false,
  }

  uploadFile = {
    show: false,
  }

  show = false

  selectFile = false

  selectedMultiple = {
    show: false,
    items: [],
  }

  filter = {
    landingFormat: null,
    whatsappTemplateCategory: null,
    whatsappTemplateStatus: null,
    whatsappTemplateLanguage: null,
    whatsappTemplateWabaId: null,
  }

// // <!-- App Preview Whatsapp -->
  static canWhatsapp = true

  constructor (canWhatsapp) {
    this.canWhatsapp = canWhatsapp
  }

  loadData (data) {
    if (data) {
      this.directories.landings = data.landings
      this.directories.voz = data.voz
      this.directories.videos = data.videos
      this.directories.whatsapp = data.whatsapp
      this.directories.imagenes = data.imagenes
      this.directories.csv = data.csv
      this.directories.audios = data.audios
      this.directories.adjuntos = data.adjuntos
      this.directories.papelera = data.papelera
      this.directories.formularios = data.formularios
      this.show = true
      this.selectedMultiple.show = false
      this.selectedMultiple.items = []
      // // <!-- App Preview Whatsapp -->
      // if (!this.canWhatsapp) {
      //   delete this.directories.whatsapp
      // }
    }
  }

  reset () {
    this.directories.landings = multimediaItems
    this.directories.voz = multimediaItems
    this.directories.videos = multimediaItems
    this.directories.whatsapp = multimediaItems
    this.directories.imagenes = multimediaItems
    this.directories.csv = multimediaItems
    this.directories.audios = multimediaItems
    this.directories.adjuntos = multimediaItems
    this.directories.papelera = multimediaItems
    this.directories.formularios = multimediaItems
    this.show = false
    this.selectedMultiple.show = false
    this.selectedMultiple.items = []
  }

  resetCreateDirectory () {
    this.createDirectory = {
      show: false,
      path: '',
    }
  }

  resetMove () {
    this.move = {
      path: '',
      newPath: '',
      type: null,
      name: '',
      itemType: null,
      fileId: null,
      validate: {
        success: false,
        message: '',
      },
      show: false,
    }
  }

  resetRename () {
    this.rename = {
      path: '',
      newPath: '',
      type: null,
      fileName: '',
      name: '',
      newName: '',
      itemType: null,
      fileId: null,
      show: false,
    }
  }

  resetDeleteDirectory () {
    this.deletedDirectory = {
      path: '',
      newPath: '',
      type: null,
      name: '',
      show: false,
    }
  }

  resetDeleteFile () {
    this.deletedFile = {
      path: '',
      newPath: '',
      type: null,
      name: '',
      fileId: null,
      show: false,
    }
  }

  resetDeleteSelectedMultiple () {
    this.selectedMultiple.show = false
    this.selectedMultiple.items = []
  }

  previewFixedMultimedia (item) {
    const multimediaContent = document.querySelector('#multimediaContent')
    const multimediaPreview = document.querySelector('#multimediaPreview')
    const multimediaContentCols = document.querySelectorAll('.multimediaContentCol')

    if (item.fileType === 'csv' || item.fileType === 'voz') {
      multimediaContent.classList.add('col-md-12', 'multimedia__content-horizontal-data')
      multimediaContent.classList.remove('col-md-9', 'multimedia__content-vertical-data')
      multimediaPreview.classList.add('col-md-12', 'multimedia__preview-horizontal-container')
      multimediaPreview.classList.remove('col-md-3', 'multimedia__preview-vertical-container')
      multimediaContentCols.forEach(function (elemen) {
        elemen.classList.add('col-md-2')
        elemen.classList.remove('col-md-3')
      })
    } else {
      multimediaContent.classList.add('col-md-9', 'multimedia__content-vertical-data')
      multimediaContent.classList.remove('col-md-12', 'multimedia__content-horizontal-data')
      multimediaPreview.classList.add('col-md-3', 'multimedia__preview-vertical-container')
      multimediaPreview.classList.remove('col-md-12', 'multimedia__preview-horizontal-container')
      multimediaContentCols.forEach(function (elemen) {
        elemen.classList.add('col-md-3')
        elemen.classList.remove('col-md-2')
      })
    }
  }

  hideFixedMultimedia () {
    const multimediaContent = document.querySelector('#multimediaContent')
    const multimediaPreview = document.querySelector('#multimediaPreview')
    const multimediaContentCols = document.querySelectorAll('.multimediaContentCol')
    multimediaContent.classList.remove('col-md-9', 'multimedia__content-vertical-data')
    multimediaContent.classList.add('col-md-12', 'multimedia__content-vertical-data')
    multimediaPreview.classList.remove('col-md-3', 'multimedia__preview-vertical-container')
    multimediaPreview.classList.remove('col-md-12', 'multimedia__preview-horizontal-container')
    multimediaContentCols.forEach((elemen) => {
      elemen.classList.add('col-md-2')
      elemen.classList.remove('col-md-3')
    })
  }
}
