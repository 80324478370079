export class PhonesWithPrefix {
  prefix = ''
  phones = []
  isoCode = ''
  phonesWithPrefix = []

  setData (isoCode, phones, prefix, phonesWithPrefix) {
    this.isoCode = isoCode
    this.phones = phones
    this.prefix = prefix
    this.phonesWithPrefix = phonesWithPrefix
  }

  setDataFromMenuOptionData (menuOptionData) {
    this.setData(menuOptionData.isoCode, [menuOptionData.phone], menuOptionData.phonePrefix, [])
  }

  reset () {
    this.setData('', [], '', [])
  }
}
