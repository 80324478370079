import EventBus from '@/util/EventBus.js'
import ViewGrid from './ViewGrid/ViewGrid.vue'
import ViewList from './ViewList/ViewList.vue'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'ContentMultimedia',
  components: {
    ViewGrid,
    ViewList,
  },
  props: {
    multimediaDirectories: { type: Object, required: true },
    multimediaPreview: { type: Object, required: true },
    fileQuota: { type: Object, required: true },
    currentDirectory: { type: Object, required: true },
    openGlobal: { type: Boolean, require: true },
    pixabayResult: { type: Object, required: true },
    sourceParams: { type: Object, required: true },
  },
  data () {
    return {
      typeView: ['grid', 'list'],
      view: 'grid',
      readyResults: false,
      hasSelectedResources: false,
      pixaBaySearchPage: 1,
      searchPixabaySearchValue: '',
      source: 'library',
      resourceTypes: {
        imagenes: 'image',
        videos: 'video',
      },
      isPixabayFirstSearch: true,
      searchTermHelper: '',
    }
  },
  computed: {
    selectedHitsIds: function () {
      return this.pixabayResult.selected.map((element) => {
        return element.id
      })
    },
    inImagesOrVideos () {
      return ['imagenes', 'videos'].includes(this.currentDirectory.path)
    },
    resourceType () {
      return this.resourceTypes[this.currentDirectory.path]
    },
  },
  watch: {
    pixaBaySearchPage () {
      this.makePixaBaySearch(this.pixaBaySearchPage)
    },
    'currentDirectory.directoryType': {
      handler () {
        this.sourceParams.source = 'library'
        this.pixabayResult.hits = []
        this.pixaBaySearchPage = 1
        EventBus.$emit('MultimediaManager:setEmptySearch')
      },
    },
  },
  methods: {
    setPixabayMultimediaPreview (hit) {
      this.multimediaPreview.reset()
      if (this.selectedHitsIds.length === 0) {
        return
      }
      this.pixabayResult.simpleHitSelected = (!this.pixabayResult.simpleHitSelected || this.pixabayResult.simpleHitSelected.id !== hit.id) ? hit : null
      this.multimediaPreview.show = false
      this.multimediaPreview.fileType = 'imagenes'
      this.multimediaPreview.source = 'pixabay'
      this.multimediaPreview.url = hit.largeImageURL

      const parts = hit.previewURL.split('/')
      const name = parts[parts.length - 1]
      this.multimediaPreview.name = name

      MultimediaService.getSizeOfSelection({ type: this.resourceType, resources: [{ url: this.multimediaPreview.url }] }).then((res) => {
        this.multimediaPreview.size = res.size
        setTimeout(() => {
          this.multimediaPreview.show = true
        })
      })
    },
    setPixabayMultimediaVideoPreview (hit) {
      this.multimediaPreview.show = false
      this.multimediaPreview.fileType = 'videos'
      this.multimediaPreview.source = 'pixabay'
      this.multimediaPreview.url = hit.videos.small.url
      const parts = hit.videos.small.url.split('/')
      const name = parts[parts.length - 1]
      const nameParts = name.split('?')
      const justName = nameParts[0]
      this.multimediaPreview.name = justName
      MultimediaService.getSizeOfSelection({ type: this.resourceType, resources: [{ url: this.multimediaPreview.url }] }).then((res) => {
        this.multimediaPreview.size = res.size
        setTimeout(() => {
          this.multimediaPreview.show = true
        })
      })
    },
    async makePixaBaySearch (page) {
      if (this.searchPixabaySearchValue === '') return
      this.readyResults = false
      MultimediaService.pixaBaySearch({ type: this.resourceType, search: this.searchPixabaySearchValue, page: page }).then((res) => {
        if (this.isPixabayFirstSearch) {
          this.searchTermHelper = '' + this.searchPixabaySearchValue
          this.isPixabayFirstSearch = false
        }

        this.pixabayResult.hits = res.hits
        this.pixabayResult.simpleHitSelected = null
        if (this.searchTermHelper !== this.searchPixabaySearchValue) {
          this.pixabayResult.selected = []
        }
        this.readyResults = true
      })
    },
    getResourcesToDownload () {
      const selected = this.pixabayResult.selected.length > 0 ? this.pixabayResult.selected : [this.pixabayResult.simpleHitSelected]
      return selected.map((element) => {
        return { url: this.resourceType === 'image' ? element.largeImageURL : element.videos.medium.url, id: element.id }
      })
    },
    downloadImages () {
      const toDownload = this.getResourcesToDownload()
      this.pixabayResult.savingResources = true
      MultimediaService.getSizeOfSelection({ type: this.resourceType, resources: toDownload }).then((res) => {
        if (res.canUpload) {
          MultimediaService.storeSelectedImages({ type: this.resourceType, resources: toDownload }).then((res) => {
            this.multimediaPreview.reset()
            this.pixabayResult.savingResources = false
            this.pixabayResult.selected = []
            EventBus.$emit('showAlert', 'success', this.$t('Las imágenes o videos se han guardado con éxito'))
          })
        } else {
          this.pixabayResult.savingResources = false
          EventBus.$emit('showAlert', 'danger', this.$t('Las imágenes o videos superan el limite de espacio de almacenamiento'))
        }
      })
    },
    addIfNotExistHit (hit) {
      const ids = this.selectedHitsIds

      if (!ids.includes(hit.id)) {
        this.pixabayResult.selected.push(hit)
      } else {
        const foundIndex = this.pixabayResult.selected.findIndex((obj) => obj.id === hit.id)
        this.pixabayResult.selected.splice(foundIndex, 1)
      }
    },
    changeView () {
      if (this.view === this.typeView[0]) {
        this.view = this.typeView[1]
      } else {
        this.view = this.typeView[0]
      }
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    EventBus.$on('changeViewContentMultimedia', () => {
      this.changeView()
    })
  },
  mounted () {
    EventBus.$on('makePixaBaySearch', (searchTerm) => {
      this.searchPixabaySearchValue = searchTerm
      this.pixaBaySearchPage = 1
      this.isPixabayFirstSearch = true
      this.searchTermHelper = ''
      this.pixabayResult.selected = []
      this.makePixaBaySearch(this.pixaBaySearchPage)
    })

    EventBus.$on('PixaBay:DownloadImages', this.downloadImages)
  },
}
