import axios from 'axios'
import moment from 'moment'
import getEnv from '@/util/env'

const API_URL = getEnv('VUE_APP_API_URL') + 'api'
const VUE_APP_OLD_DASHBOARD_URL = getEnv('VUE_APP_OLD_DASHBOARD_URL')
const TWO_WAY_REMEMBER_TOKEN = 'twoWayRememberToken'

class AuthService {
  async login (auth) {
    const response = await axios.post(API_URL + '/login', {
      username: auth.username,
      password: auth.password,
      two_way_remember_360: localStorage.getItem(TWO_WAY_REMEMBER_TOKEN) ?? null,
    })

    this.checkAutoLogin(response.data)

    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
  }

  async autologin (autologinData) {
    const response = await axios.post(API_URL + '/auto-login', autologinData)

    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }

    if (response.data.rememberToken) {
      localStorage.setItem(TWO_WAY_REMEMBER_TOKEN, response.data.rememberToken)
    }

    return response.data
  }

  checkAutoLogin (data) {
    if (Object.keys(data).includes('autoLogin') && data.autoLogin === true) {
      const redirect = VUE_APP_OLD_DASHBOARD_URL + 'autologin/' + data.username + '/' + data.autoLoginToken
      window.location.href = redirect
    }
  }

   async twoWayCheck (auth) {
    const response = await axios.post(API_URL + '/two-way-check', {
      username: auth.username,
      rememberme: auth.rememberme,
      logged_token: auth.loggedToken,
      two_way_code: auth.twoWayCode,
    })

    if (response.data.rememberToken) {
      localStorage.setItem('twoWayRememberToken', response.data.rememberToken)
    }

    this.checkAutoLogin(response.data)

    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
  }

  async refresh () {
    try {
      console.log('refresh token inicio')
      const user = JSON.parse(localStorage.getItem('user'))
      console.log('refresh token user', user)
      const response = await axios.get(API_URL + '/refresh', {
        headers: { Authorization: 'Bearer ' + user.token },
      })

      console.log('refresh token response', response)

      if (response.data.token) {
        console.log('refresh token hay token', response.data.token)
        localStorage.setItem('user', JSON.stringify(response.data))
        return true
      }

      console.log('refresh token no hay token')

      return false
    } catch (error) {
      return false
    }
  }

  async logout () {
    const user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.get(API_URL + '/logout', {
      headers: { Authorization: 'Bearer ' + user.token },
    })
    this.clearLocalStorageUserData()
    return response.data
  }

  async removeUserIfExipred () {
    const validateRefreshtoken = await this.validateRefreshToken()

    if (!validateRefreshtoken) {
      this.clearLocalStorageUserData()
    }

    const user = JSON.parse(localStorage.getItem('user'))
    if (!user) {
      return
    }
    const response = await axios.get(API_URL + '/user', {
      headers: { Authorization: 'Bearer ' + user.token },
    })

    if (response.data.status === 'Token is Expired' || response.data.status === 'Token is Invalid') {
      this.clearLocalStorageUserData()
    }
  }

  authHeader () {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token }
    } else {
      return {}
    }
  }

  async validateRefreshToken () {
    const user = JSON.parse(localStorage.getItem('user'))

    if (!user) {
      return false
    }

    // const localDate = moment.utc(user.tokenExpire).toDate()
    // const dateTokenExpire = moment(localDate).local().format('YYYY-MM-DD kk:mm:ss')
    // const date = moment().format('YYYY-MM-DD kk:mm:ss')

    const dateTokenExpire = moment(user.tokenExpire)
    const date = moment()

    if (date.format('YYYY-MM-DD kk:mm:ss') >= dateTokenExpire.format('YYYY-MM-DD kk:mm:ss')) {
      const refresh = await this.refresh()

      if (!refresh) {
        return false
      }
    }

    return true
  }

  async clearLocalStorageUserData () {
    localStorage.removeItem('sectionsBlockeds')
    localStorage.removeItem('user')
  }
}

export default new AuthService()
