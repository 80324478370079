import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { FadeTransition } from 'vue2-transitions'
import AppBar from '@/components/Layout/AppBar/AppBar.vue'
import Loading from '@/components/Modals/Loading/Loading.vue'
import MultimediaManager from '@/components/MultimediaManager/MultimediaManager.vue'
import Alert from '@/components/Alert/Alert.vue'
import SupportContactButton from '@/components/SupportContactButton/SupportContactButton.vue'
import EventBus from '@/util/EventBus'
import { ValidatorLocalize } from '@/util/ValidatorLocalize'
import accountService from '@/services/account.service'
import { Languages } from '@/classes/Languages'
import { mapState } from 'vuex'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    PerfectScrollbar(`.${className}`)
  } else {
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  name: 'PrivateLayout',
  components: {
    FadeTransition,
    AppBar,
    MultimediaManager,
    Alert,
    Loading,
    SupportContactButton,
  },
  data () {
    return {
      languages: Languages.get(),
      drawer: null,
      leftDrawer: null,
      minify: true,
      showLoading: false,
      loadingMessage: null,
      group: null,
      maxWidth: '1600px',
      isDltAvailable: false,
      user: this.$store.getters['auth/getUser'],
    }
  },
  computed:
    mapState({
      logo: state => state.logo,
      balance: state => state.balance,
      showSupportButton () {
        const username = JSON.parse(localStorage.getItem('user')).username
        return ['360ACADEMY'].indexOf(username) < 0
      },
      showUserIsoTld () {
        const user = this.$store.getters['auth/getUser']
        return user.isoCountry?.toLowerCase() || user.isoTld?.toLowerCase()
      },
      canWhatsapp () {
        return this.$store.getters['auth/getUser'].enable?.wa
      },
      hasReferrer () {
        return this.user.referrer
      },
      referrerHeaderLogo () {
        return this.user.referrer?.logoHeader
      },
      referrerFooterLogo () {
        return this.user.referrer?.logoFooter
      },
    }),
  methods: {
    initScrollbar () {
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    },
    setLoading (loading, loadingMessage = null) {
      this.showLoading = loading
      this.loadingMessage = loadingMessage
    },
    setMaxWidth (maxWidth = '1600px') {
      this.maxWidth = maxWidth
    },
    setIsoFlag (isoCode) {
      const lang = isoCode === 'en' ? 'gb' : isoCode
      return require('./../../../assets/img/flags/' + lang + '.svg')
    },
    changeLanguage (langIsoCode) {
      this.userLanguage = langIsoCode
      this.$i18n.locale = langIsoCode
      ValidatorLocalize.setLanguage(this.userLanguage)
      localStorage.setItem('language', langIsoCode)
      this.$store.dispatch('auth/setUserLanguage', langIsoCode)
      const params = {
        locale: langIsoCode,
      }
      accountService.setUserLanguage(params)
      .then(
        (response) => {
          this.getNews()
          EventBus.$emit('changedLanguage', langIsoCode)
        },
        (err) => {
          console.log(err)
        },
      )
    },
    logout () {
      this.$store.dispatch('auth/logout').then(
        () => {
          localStorage.removeItem('invoiced')
          this.$router.push('/login')
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar cerrar sesión'))
          }
          EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.error))
        },
      )
    },
  },
  created () {
    EventBus.$on('showLoading', this.setLoading)
    EventBus.$on('setMaxWidth', this.setMaxWidth)
  },
  destroyed () {
    EventBus.$off('showLoading', this.setLoading)
    EventBus.$off('setMaxWidth', this.setMaxWidth)
  },
  mounted () {
    this.initScrollbar()
  },
}
