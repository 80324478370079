import EmojiPicker from 'vue-emoji-picker'

export default {
  name: 'EmojiPickerInput',
  components: { EmojiPicker },
  props: {
    top: {
      type: String,
      required: false,
      default: '0px',
    },
    left: {
      type: String,
      required: false,
      default: '-260px',
    },
  },
  data () {
    return {
      input: '',
      search: '',
    }
  },
  methods: {
    append (emoji) {
      this.$emit('onSelectEmoji', emoji)
    },
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      },
    },
  },
}
