import { Country } from '@/util/Country.js'

export default {
  props: [
    'countriesWithPhonePrefix',
    'limit',
    'phonesWithPrefix',
    'defaultCountryIso',
    'isPreview',
    'countryPrefixPlaceholder',
    'disposition',
  ],
  name: 'PhonesWithPrefix',
  components: { },
  data: function () {
    return {
      tag: '',
      tags: [],
      isoCode: '',
      phonesWithPrefixList: [],
      prefix: '',
      phonesLength: 0,
      canShowError: false,
      searchInput: null,
      searchedCountriesWithPhonePrefix: [...this.countriesWithPhonePrefix],
      loading: false,
      Country: Country,
    }
  },
  methods: {
    removeTag (value) {
      this.tags = this.tags.filter((tag) => {
        return tag !== value
      })
      this.checkTagsChange()
    },
    tagsChanged (newTags) {
      this.tags = newTags
      this.emitPhonesWithPrefixUpdate()
    },
    updatePhonePrefix () {
      const isoCode = this.isoCode
      let prefix = ''
      this.countriesWithPhonePrefix.forEach(countryWithPhonePrefix => {
        // eslint-disable-next-line dot-notation
        if (isoCode === countryWithPhonePrefix.isoTld) {
          prefix = countryWithPhonePrefix.prefix
          return false
        }
      })
      this.prefix = prefix
    },
    emitPhonesWithPrefixUpdate () {
      this.updatePhonePrefix()
      const phonesWithPrefixList = []
      const prefix = this.prefix
      const phones = []
      this.tags.forEach(function (value) {
        let phoneWithPrefix = String(value)
        if (!phoneWithPrefix.startsWith(prefix)) {
          phoneWithPrefix = prefix + phoneWithPrefix
        }
        phones.push(value)
        phonesWithPrefixList.push(phoneWithPrefix)
      })
      this.phonesWithPrefixList = phonesWithPrefixList
      this.phonesWithPrefix.setData(this.isoCode, phones, this.prefix, phonesWithPrefixList)
      this.$emit('phones-with-prefix-update', this.phonesWithPrefix)
      this.phonesLength = this.phonesWithPrefixList.length
      this.canShowError = true
    },
    checkTagsLimit (obj) {
      if (!this.limit || this.phonesWithPrefixList.length < this.limit) {
        obj.addTag()
      }
    },
    normalizeUnderscore (value) {
      return value.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')
    },
    removeSelected (data) {
      this.isoCode = ''
    },
    checkTagsChange () {
      if (this.tags.length > this.limit) {
        this.tags.pop()
      }
      this.$forceUpdate()
      this.emitPhonesWithPrefixUpdate()
    },
  },
  watch: {
    tags: {
      handler: function () {
        this.checkTagsChange()
      },
      deep: true,
    },
    isoCode () {
      this.emitPhonesWithPrefixUpdate()
    },
    searchInput (seachValue) {
      const searchValueLowerCase = this.normalizeUnderscore(seachValue)
      this.searchedCountriesWithPhonePrefix = this.countriesWithPhonePrefix.filter((countryWithPhonePrefix) => {
        return this.normalizeUnderscore(countryWithPhonePrefix.name).includes(searchValueLowerCase) || this.normalizeUnderscore(countryWithPhonePrefix.prefix).includes(searchValueLowerCase)
      })
    },
  },
  created () {
    if (!this.phonesWithPrefix) {
      return
    }
    this.isoCode = this.phonesWithPrefix.isoCode
    this.tags = []

    this.phonesWithPrefix.phones.forEach(phone => {
      this.tags.push(phone)
    })
    this.phonesLength = this.phonesWithPrefix.phonesWithPrefix.length
    if (this.defaultCountryIso) {
      this.isoCode = this.defaultCountryIso.toLowerCase()
    }
  },
  mounted () {
  },
}
