export default class HeadboardType {
  static headboardTypes = {
    TEXT: 1,
    HEADER_TEXT: 2,
    CALL_ACTION_TEXT: 3,
    CALL_ACTION_HEADER_TEXT: 4,
    RESPONSE_TEXT: 5,
    RESPONSE_HEADER_TEXT: 6,
    CALL_ACTION_TEXT_AUTH: 7,
    OPTION_TEXT: 8,
    OPTION_HEADER_TEXT: 9,
  }

  static optionsText = {
    TEXT: 'Texto',
    HEADER_TEXT: 'Texto con cabecera',
    CALL_ACTION_TEXT: 'Texto con botones de acción',
    CALL_ACTION_HEADER_TEXT: 'Texto con cabecera y botones de acción',
    RESPONSE_TEXT: 'Texto con botones de respuesta rápida',
    RESPONSE_HEADER_TEXT: 'Texto con cabecera y botones de respuesta rápida',
    CALL_ACTION_TEXT_AUTH: 'Texto y botones de acción de autenticación',
    OPTION_TEXT: 'Texto y opciones',
    OPTION_HEADER_TEXT: 'Texto con cabecera y opciones',
  }

  static isHeaderOption (value) {
    return [
      this.headboardTypes.HEADER_TEXT,
      this.headboardTypes.CALL_ACTION_HEADER_TEXT,
      this.headboardTypes.OPTION_HEADER_TEXT,
    ].includes(value)
  }

  static isCallToActionOption (value) {
    return [
      this.headboardTypes.CALL_ACTION_TEXT,
      this.headboardTypes.CALL_ACTION_HEADER_TEXT,
      this.headboardTypes.OPTION_TEXT,
      this.headboardTypes.OPTION_HEADER_TEXT,
    ].includes(value)
  }

  static isAutomaticReponseOption (value) {
    return [
      this.headboardTypes.RESPONSE_TEXT,
      this.headboardTypes.RESPONSE_HEADER_TEXT,
      this.headboardTypes.OPTION_TEXT,
      this.headboardTypes.OPTION_HEADER_TEXT,
    ].includes(value)
  }
}

