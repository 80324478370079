import { Service } from './service'

const PATH = 'campaign/dlt-validations'

class CampaignDltValidationService extends Service {
  getAll (params = {}) {
    return this.get(PATH, params)
  }

  getApproved (params = {}) {
    return this.get(`${PATH}/approved`, params)
  }

  create (params = {}) {
    return this.post(PATH, params)
  }

  update (id, params = {}) {
    return this.put(`${PATH}/${id}`, params)
  }

  destroy (id) {
    return this.delete(`${PATH}/${id}`)
  }
}

export default new CampaignDltValidationService()
