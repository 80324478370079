import { Menu } from '@/models/voice/Menu'
import { MenuOptionType } from '@/models/voice/MenuOptionType'
import MenuOptions from '@/components/Template/Voice/MenuOptions/MenuOptions.vue'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import PhonesWithPrefix from '@/components/PhonesWithPrefix/PhonesWithPrefix.vue'
import TextToSpeechSource from '@/components/Template/Voice/TextToSpeechSource/TextToSpeechSource.vue'

export default {
  props: ['template', 'componentsData'],
  name: 'VoicePreview',
  components: { MenuOptions, PhonesWithPrefix, TextToSpeechSource },
  data: function () {
    return {
      menu: new Menu(),
      phonesWithPrefix: new PhonesWithPrefixClass(),
      showSubMenuComponent: false,
      subMenuHelper: new Menu(),
      fields: {
        availableFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
    }
  },
  computed: {
    errorOrOmissionOptions: function () {
      return [
        { value: 'Play', text: this.$t('Reproducir audio').toString() },
        { value: 'CallTransfer', text: this.$t('Transferir llamada').toString() },
      ]
    },
  },
  methods: {
    setShowSubmenuComponent (value) {
      this.showSubMenuComponent = value
    },
    getMenuOptionTextByTypeId (typeId) {
      switch (typeId) {
        case MenuOptionType.ADD_TO_BLACK_LIST:
          return this.$t('Añadir a Blacklist').toString()
        case MenuOptionType.GET_DATA_WHILE_PLAYING:
          return this.$t('Submenú').toString()
        case MenuOptionType.CALL_TRANSFER:
          return this.$t('Transferir').toString()
        case MenuOptionType.REPEAT_MESSAGE:
          return this.$t('Repetir mensaje').toString()
        case MenuOptionType.PLAY:
          return this.$t('Reproducir').toString()
        case MenuOptionType.PLAY_AND_SEND_SMS:
          return 'SMS'
      }
    },
    setPreviewData () {
      const content = this.template.content
        if (Object.keys(content).length !== 0) {
          this.menu.setData(content)
          if (this.menu.menuOptions.length > 0) {
            this.phonesWithPrefix.isoCode = this.menu.menuOptions[0].data.isoCode
            this.phonesWithPrefix.prefix = this.menu.menuOptions[0].data.phonePrefix
            this.phonesWithPrefix.phones = [this.menu.menuOptions[0].data.phone]
          }
        }
    },
  },
  watch: {
    template: {
      handler: function () {
        this.setPreviewData()
      },
    },
  },
  created () {
    this.setPreviewData()
  },
}
