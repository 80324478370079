export class SmsObject {
    clickable = false
    message = ''
    sender = ''
    optOut = ''
    encoding = 'GSM'
    bookmark = false
    attachments = []
    countryIso = 'ES'
    templateId = null
    templateStructure = null

    constructor (data) {
      if (data) {
        this.setData(data)
      }
    }

    setData (data) {
      this.clickable = data.clickable
      this.message = data.message
      this.sender = data.sender
      this.optOut = data.optOut
      this.encoding = data.encoding
      this.bookmark = data.bookmark
      this.attachments = data.attachments
      this.countryIso = data.countryIso
      this.templateId = data.templateId
      this.templateStructure = data.templateStructure
    }

    setDataFromSmsMenuOption (data) {
      this.sender = data.sender
      this.encoding = data.encoding
      this.message = data.textMessage
      this.bookmark = data.bookmark
      this.templateId = data.templateId
      this.templateStructure = data.templateStructure
    }

    reset () {
      this.clickable = false
      this.message = ''
      this.sender = ''
      this.optOut = ''
      this.encoding = 'GSM'
      this.bookmark = false
      this.attachments = []
      this.countryIso = 'ES'
      this.templateId = null
      this.templateStructure = null
    }
}

