import SupportService from '@/services/support.service'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'SupportContactButton',
  data () {
    return {
      showForm: false,
      showFormInputs: true,
      messageResult: 0,
      supportMessage: {
        subject: null,
        message: null,
      },
    }
  },
  methods: {
    showSendingForm () {
      this.showForm = !this.showForm
      this.showFormInputs = true
    },
    sendForm () {
      const data = {
        subject: this.supportMessage.subject,
        message: this.supportMessage.message,
      }
      SupportService.sendSupportMessage(data)
      .then(
        (response) => {
          this.messageResult = response.sent
        },
        () => {
          this.messageResult = 0
        },
      )
      .finally(_ => {
        this.showFormInputs = false
        this.clearForm()
      })
    },

    clearForm () {
      this.supportMessage = {
        subject: null,
        message: null,
      }
    },

    onClickOutside () {
      this.showForm = false
    },

    onCloseConditional (e) {
      return this.showForm
    },
  },
  mounted () {
    EventBus.$on('showSupportForm', () => {
      this.showSendingForm()
    })
  },
}
