import { Service } from './service'

const PATH = 'template/landing/'
const SHARE_PATH = 'share/template/landing/'


class LandingTemplateService extends Service {
  generatePreview (data = {}) {
    return this.post(PATH + 'generate-preview', data)
  }

  getPreviewFromStructure (data = {}) {
    return this.post(PATH + 'get-preview-from-structure', data)
  }

  create (data = {}) {
    return this.post(PATH + 'create', data)
  }

  save (data = {}) {
    return this.post(PATH + 'save-landing', data)
  }

  getOrCreateLanding (path) {
    const data = {}
    if (path) {
      data.path = path
    }
    return this.get(PATH + 'edit', data)
  }

  getLandingByPath (path) {
    return this.post(PATH + 'get-landing-by-path', path)
  }

  getLadingPreview (params = {}) {
    return this.get(PATH + 'generate-preview', params)
  }

  getLandingBbyPath (params = {}) {
    return this.post(PATH + 'generate-preview', params)
  }

  getVimeoVideoData (params = {}) {
    return this.get(PATH + 'get-vimeo-video-data', params)
  }


  // share

  getSharePreviewFromStructure (data = {}, shareHash) {
    return this.post(SHARE_PATH + 'get-preview-from-structure?shareHash=' + shareHash, data)
  }
}

export default new LandingTemplateService()

