export class WhatsappTemplateStatus {
  static APPROVED = 0
  static IN_APPEAL = 1
  static PENDING = 2
  static REJECTED = 3
  static PENDING_DELETION = 4
  static DELETED = 5
  static DISABLED = 6
  static PAUSED = 7
  static LIMIT_EXCEEDED = 8
  static DRAFT = 9
  static SENT_TO_VALIDATE = 10
  static SUBMITTED = 11
  static INCORRECT_FORMAT = 12
}
