import { Service } from './service'

const PATH = 'support'

class SupportService extends Service {
  sendSupportMessage (data = {}) {
    return this.post(PATH + '/send-message', data)
  }
}

export default new SupportService()
