

export default {
  name: 'DltVariableSubstitutionDialog',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    dltMessage: {
      required: true,
    },
    // dltVarFields: {
    //   type: Array,
    //   required: true,
    // },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      dltVarFields: [],
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    acceptModal () {
      this.$emit('accept', {
        ...this.dltMessage,
        template_content: this.substituteVarsMessage(),
      })
    },
    substituteVarsMessage (display = false) {
      let idx = 0
      return this.dltMessage?.template_content
        .replace(/\{#.+?#\}/g, (match) => {
          let substitution = match
          if (this.dltVarFields[idx]?.customField) {
            substitution = '{' + this.dltVarFields[idx]?.customField + '}'
            if (display) {
              substitution = `<b>${substitution}</b>`
            }
          } else {
            substitution = `{#var#}<sup>${idx + 1}</sup>`
          }
          idx++
          return substitution
        })
    },
    setDltVarFields () {
      this.dltVarFields = (this.dltMessage.template_content.match(/\{#.+?#\}/g) || [])
        .map((field) => ({
          field,
          customField: null,
        }))
    },
  },
  computed: {
    currentMessage () {
      return this.substituteVarsMessage(true)
    },
  },
  mounted () {
    this.setDltVarFields()
  },
}
