import $t from '../../../../util/Translator'

export class LandingFormats {
  static BLOCKS_ID = 0
  static HTML_ID = 1

  static getFormatName = (id) => {
    const format = LandingFormats.formats.find(element => element.id === id)
    return format ? format.name : ''
  }

  static getFormatColor = (id) => {
    const format = LandingFormats.formats.find(element => element.id === id)
    return format ? format.color : ''
  }

  static getFormat = (id) => {
    return LandingFormats.formats.find(element => element.id === id)
  }

  static formats = [
      {
        id: LandingFormats.BLOCKS_ID,
        name: $t('Bloques'),
        color: '#05ADE6',
      },
      {
        id: LandingFormats.HTML_ID,
        name: 'HTML',
        color: '#47cc5d',
      },
    ]
}
