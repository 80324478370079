export class AvailableKeys {
  keysDefinition
  keys
  menuKeys

  constructor () {
    // eslint-disable-next-line no-magic-numbers
    this.keysDefinition = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    this.keys = [...this.keysDefinition]
    this.menuKeys = []
  }

  isAvailableKey (key) {
    return this.keys.includes(key)
  }

  isUnavailableKey (key) {
    return !this.isAvailableKey(key)
  }

  getKeys () {
    return this.keys
  }

  setMenuKeys (menuKey) {
    this.menuKeys[menuKey] = [...this.keysDefinition]
  }

  removeKeys (keys) {
    for (const key of keys) {
      this.removeKey(key)
    }
  }

  removeKey (menuOptionKey) {
    this.keys = this.filterByKey(this.keys, menuOptionKey)
  }

  filterByKey = function (keys, menuOptionKey) {
    return keys.filter(function (key) {
      return key !== menuOptionKey
    })
  }
}
