import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'RenameItem',
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      show: false,
      loading: false,
      itemPath: false,
      item: {
        name: '',
        path: '',
      },
      extension: '',
    }
  },
  methods: {
    close () {
      this.resetFormFile()
      this.multimediaDirectories.resetRename()
    },
    resetFormFile () {
      this.$refs.formRename.reset()
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observer.reset()
    },
    async renameFile () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      if (this.multimediaDirectories.rename.name !== (this.multimediaDirectories.rename.newName + this.extension)) {
        this.loading = true
        const params = {
          path: this.multimediaDirectories.rename.path,
          type: this.multimediaDirectories.rename.type,
          fileName: this.multimediaDirectories.rename.fileName,
          newFileName: this.multimediaDirectories.rename.newName + this.extension,
          fileId: this.multimediaDirectories.rename.fileId,
        }
        MultimediaService.renameItem(params).then(
          (response) => {
            if (response.success === true) {
              this.close()

              this.multimediaPreview.reset()
              this.multimediaDirectories.loadData(response.multimediaDirectories)
              const split = this.currentDirectory.path.split('/')
              let directory = this.multimediaDirectories.directories[split[0]]
              for (let i = 1; i < split.length; i++) {
                directory = directory.directories[split[i]]
              }
              this.currentDirectory.loadData(directory)

              EventBus.$emit('showAlert', 'success', this.$t(response.code))
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t(response.code))
            }
          },
          (error) => {
            EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
          },
          )
        .finally(() => {
          this.loading = false
        })
      }
    },
    getFileName () {
      const lastDot = this.multimediaDirectories.rename.name.lastIndexOf('.')
      if (lastDot >= 0) {
        this.extension = this.multimediaDirectories.rename.name.substr(lastDot)
        this.multimediaDirectories.rename.newName = this.multimediaDirectories.rename.name.substr(0, lastDot)
      }
    },
  },
  computed: {
    itemType () {
      return this.multimediaDirectories.rename.type === 'file' ? this.$t('Archivo').toLowerCase() : this.$t('carpeta').toLowerCase()
    },
  },
  updated () {
    if (!this.multimediaDirectories.rename.newName.length) {
      this.extension = ''
      this.multimediaDirectories.rename.newName = this.multimediaDirectories.rename.name
      if (this.multimediaDirectories.rename.type === 'file') {
        this.getFileName()
      }
    }
  },
}
