var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(invalid || !invalid)?_c('div',[_vm._v(" "+_vm._s(_vm.setInvalidElements(invalid))+" ")]):_vm._e(),_c('div',[_c('v-row',{staticClass:"mrow-0"},[(_vm.label)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.speechOptions,"label":_vm.$t('Fuente de audio'),"item-text":'text',"item-value":'value',"disabled":_vm.isPreview,"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},on:{"change":_vm.changeUrlSource},model:{value:(_vm.menuOptionData.type),callback:function ($$v) {_vm.$set(_vm.menuOptionData, "type", $$v)},expression:"menuOptionData.type"}})],1),_c('v-col',{staticClass:"d-flex pb-0",attrs:{"cols":3}},[(_vm.menuOptionData.url !== '')?_c('audio',{staticClass:"audio-tag",attrs:{"controls":"","controlslist":"nodownload novolume"}},[_c('source',{attrs:{"src":_vm.menuOptionData.url}})]):_vm._e()])],1),_c('v-row',{staticClass:"mrow-0"},[_c('v-col',{staticClass:"mt-n1 py-0",attrs:{"cols":_vm.label ? 9 : 12,"offset":_vm.label ? 3 : 0}},[(_vm.menuOptionData.type === 'audio' && _vm.menuOptionData.url !== '')?_c('v-btn',{staticClass:"badge-success ls-0 font-weight-300",staticStyle:{"cursor":"default"},attrs:{"elevation":"0","ripple":false}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v(" fas fa-file-audio ")]),_vm._v(" "+_vm._s(_vm.audioFileName)+" ")],1):_vm._e(),(_vm.menuOptionData.type == 'speech')?_c('div',[(_vm.componentsData)?_c('v-btn-toggle',[_c('v-menu',{attrs:{"max-height":"33%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-bottom-radius-0 btn-action-sms-sender-personalizados-top",attrs:{"small":"","color":"white"}},'v-btn',attrs,false),menu),[_c('i',{staticClass:"fas fa-star pr-2"}),_vm._v(" "+_vm._s(_vm.$t('Cargar favoritos'))+" ")])]}}],null,true)},[_c('v-list',[_c('v-subheader',{staticClass:"selector-header"},[_vm._v(" "+_vm._s(_vm.$t('Mensajes favoritos'))+" ")]),_vm._l((_vm.componentsData.bookmarks),function(bookmark,key){return _c('v-list-item',{key:bookmark.id,on:{"click":function($event){return _vm.addFieldToMessage(key, 'bookmark')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("dateLocal")(bookmark.created_at))+" - "+_vm._s(bookmark.message)+" ")])],1)})],2)],1),_c('v-menu',{attrs:{"max-height":"33%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-bottom-radius-0 btn-action-sms-sender-personalizados-top",attrs:{"small":"","color":"white"}},'v-btn',attrs,false),menu),[_c('i',{staticClass:"fas fa-history pr-2"}),_vm._v(" "+_vm._s(_vm.$t('Mensajes anteriores'))+" ")])]}}],null,true)},[_c('v-list',[_c('v-subheader',{staticClass:"selector-header"},[_vm._v(" "+_vm._s(_vm.$t('Mensajes anteriores'))+" ")]),_vm._l((_vm.componentsData.latestsLocutions),function(lastSms,key){return _c('v-list-item',{key:lastSms.id,on:{"click":function($event){return _vm.addFieldToMessage(key, 'last-sms')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("dateLocal")(lastSms.created_at))+" - "+_vm._s(lastSms.message)+" ")])],1)})],2)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-bottom-radius-0 btn-action-sms-sender-personalizados-top btn-action-agregar-favoritos ",class:{ 'btn-action-book-mark' : _vm.campaign.sendings[0].channel.bookmark },attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.toogleBookMarks()}}},'v-btn',attrs,false),menu),[_c('i',{staticClass:"far fa-star pr-2"}),_vm._v(" "+_vm._s(_vm.$t('Agregar a favoritos'))+" ")])]}}],null,true)}),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-bottom-radius-0 btn-action-sms-sender-personalizados-top blue lighten-2  white--text",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.openAiAssistant()}}},'v-btn',attrs,false),menu),[_c('i',{staticClass:"  far fa-lightbulb pr-2"}),_vm._v(" "+_vm._s(_vm.$t('Generar con IA'))+" ")])]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('mensaje'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{ref:"messageInput",staticClass:"form-control",attrs:{"disabled":_vm.isPreview,"rows":"3","error-messages":errors,"placeholder":_vm.$t('Escriba aquí su locución'),"name":"text-message","label":_vm.$t('Mensaje'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),(_vm.fields)?_c('v-col',[_c('v-row',[_c('v-btn-toggle',{staticStyle:{"width":"100% !important","margin-top":"-29px"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-sms-sender btn-action-sms-sender-personalizados border-top-l-radius-0 border-top-r-radius-0",staticStyle:{"border-bottom-right-radius":"6px !important"},attrs:{"small":"","color":"white","disabled":!_vm.campaign.sendings[0].totalRecipients > 0}},'v-btn',attrs,false),menu),[_c('span',{staticClass:"ico-mini mr-2"},[_vm._v("{ } ")]),_vm._v(_vm._s(_vm.$t('Campos personalizados'))+" ")])]}}],null,true)},[_c('v-list',[_c('v-subheader',{staticClass:"selector-header"},[_vm._v(" "+_vm._s(_vm.$t('Campos personalizados'))+" ")]),_vm._l((_vm.fields.availableFields),function(field,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.addFieldToMessage(index, 'field')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(field)+" ")])],1)})],2)],1)],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.menuOptionData.type == 'audio' && !_vm.isPreview)?_c('div',{staticClass:"form-group"},[(_vm.menuOptionData.url === '')?_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"rules":"required"}},[_c('v-text-field',{model:{value:(_vm.noAudioUrlHelper),callback:function ($$v) {_vm.noAudioUrlHelper=$$v},expression:"noAudioUrlHelper"}})],1):_vm._e(),_c('button-multimedia',{attrs:{"current-root-directory":"audios","button-text":_vm.$t('Gestor Multimedia')},on:{"onSelectedMultimediaFile":_vm.selectAudioFile,"click":function($event){_vm.isSelectingAudio = true}}}),_c('div',{staticClass:"form-group mt-1"},[_c('div',{staticClass:"alert alert-custom alert-default",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-icon"},[_c('i',{staticClass:"flaticon2-information text-info icon-lg"})]),_c('div',{staticClass:"alert-text text-center text-body-2 audio-formats-txt"},[_vm._v(" "+_vm._s(_vm.$t( "Los formatos soportados son mp3, ogg, wav y flac" ))+" ")])])])],1):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }