import { Service } from './service'

const PATH = 'group/'

class GroupService extends Service {
  listCountriesByGroupsId (params = {}) {
    return this.post(PATH + 'listCountriesByGroupsId', params)
  }

  getAllGroups (params = {}) {
    return this.post(PATH + 'getAllGroups', params)
  }

  getUsers (params = {}) {
    return this.get(PATH + 'users', params)
  }

  getUsersNoAnonymous (params = {}) {
    return this.get(PATH + 'users-no-anonymous', params)
  }

  getUserAssigns (params = {}) {
    return this.get(PATH + 'users-assing', params)
  }

  getUserGroups (params = {}) {
    return this.get(PATH + 'user-groups', params)
  }

  getGroupsData (params = {}) {
    return this.get(PATH + 'groups-data', params)
  }

  getGroupsById (params = {}) {
    return this.get(PATH + 'groups-by-id', params)
  }

  removeGroup (params) {
    return this.post(PATH + 'delete', params)
  }

  duplicate (params) {
    return this.post(PATH + 'duplicate', params)
  }

  rename (params) {
    return this.post(PATH + 'rename', params)
  }

  getContacts (params = {}) {
    return this.post(PATH + 'contacts-from-group-and-channel', params)
  }

  getWithContactsSendingsByChannel (params = {}) {
    return this.post(PATH + 'contacts-sendings-by-channel', params)
  }

  getWithContactsSendingsByChannelWithDates (params = {}) {
    return this.post(PATH + 'contacts-sendings-by-channel-dates', params)
  }

  getWithContactsSendingsByChannelNoPagination (params = {}) {
    return this.post(PATH + 'contacts-sendings-by-channel-no-pagination', params)
  }

  checkRoutesForGroups (params = {}) {
    return this.post(PATH + 'check-routes-for-groups', params)
  }

  requestRouting (params = {}) {
    return this.post(PATH + 'request-routing', params)
  }

  getAllContacts (groupId) {
    return this.get(PATH + 'groups-contacts/' + groupId, {})
  }

  getAllContactsCountries (params = {}) {
    return this.post(PATH + 'groups-contacts-countries', params)
  }

  exists (params = {}) {
    return this.post(PATH + 'exists', params)
  }

  showCount (params = {}) {
    return this.post(PATH + 'showcount', params)
  }

  emailCount (params = {}) {
    return this.post(PATH + 'count-emails', params)
  }

  submitData (params = {}) {
    return this.post(PATH + 'submit', params)
  }
}

export default new GroupService()
