import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'UploadFile',
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      file: null,
    }
  },
  methods: {
    open () {
      const file = this.$refs.uploader
      if (this.rootDirectory() === 'imagenes') {
        file.accept = 'image/png,image/jpg,image/jpeg,image/gif'
      }
      if (this.rootDirectory() === 'audios') {
        file.accept = 'audio/mp3,audio/ogg,audio/mp4,audio/wav,audio/mpeg,audio/aiff,audio/mid,audio/xm,audio/flac,audio/mkv,audio/s3m'
      }
      if (this.rootDirectory() === 'csv') {
        file.accept = '.csv'
      }
      if (this.rootDirectory() === 'adjuntos') {
        file.accept = '.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt'
      }
      if (this.rootDirectory() === 'videos') {
        file.accept = 'video/mp4,video/ogg,video/webm,video/avi,video/mkv,video/mov,video/wmv,video/flv,video/3gp'
      }
      file.click()

      this.multimediaDirectories.uploadFile.show = false
    },
    changeFile () {
      this.file = this.$refs.uploader.files[0]
      if (this.file) {
        this.uploadFileMultimedia()
      }
    },
    uploadFileMultimedia () {
      EventBus.$emit('showLoading', true)
      const formData = new FormData()
      formData.append('file', this.file, this.file.name)
      formData.append('path', this.currentDirectory.path)
      this.clearInputFile()

      if (this.rootDirectory() === 'audios') {
        MultimediaService.uploadFileAudio(formData).then(
          (response) => {
            if (response.success === true) {
              this.multimediaPreview.reset()
              this.multimediaDirectories.loadData(response.multimediaDirectories)
              const split = this.currentDirectory.path.split('/')
              let directory = this.multimediaDirectories.directories[split[0]]
              for (let i = 1; i < split.length; i++) {
                directory = directory.directories[split[i]]
              }
              this.currentDirectory.loadData(directory)

              EventBus.$emit('showAlert', 'success', this.$t(this.$t(response.code)))
              this.fileQuota.loadData(response.fileQuota)
              this.$nextTick(() => EventBus.$emit('selectFileMultimedia', response.file))
              this.$nextTick(() => EventBus.$emit('selectItem', response.file))
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t(response.code))
            }
          },
          (error) => {
            if (error.response.status === 413) {
              EventBus.$emit('showAlert', 'danger', this.$t('El tamaño del archivo máximo para este tipo de archivo es 100 MB'))
              return
            }
            EventBus.$emit('showAlert', 'danger', error.response.data.file[0])
          },
        )
        .finally(() => {
          EventBus.$emit('showLoading', false)
        })
      } else {
        MultimediaService.uploadFile(formData).then(
          (response) => {
            if (response.success === true) {
              this.multimediaPreview.reset()
              this.multimediaDirectories.loadData(
                response.multimediaDirectories,
              )
              const split = this.currentDirectory.path.split('/')
              let directory = this.multimediaDirectories.directories[split[0]]
              for (let i = 1; i < split.length; i++) {
                directory = directory.directories[split[i]]
              }
              this.currentDirectory.loadData(directory)

              EventBus.$emit(
                'showAlert',
                'success',
                this.$t(this.$t(response.code)),
              )
              this.fileQuota.loadData(response.fileQuota)
              this.$nextTick(() =>
                EventBus.$emit('selectFileMultimedia', response.file),
              )
              this.$nextTick(() => EventBus.$emit('selectItem', response.file))
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t(response.code))
            }
          },
          (error) => {
            if (error.response.status === 413) {
              EventBus.$emit('showAlert', 'danger', this.$t('El tamaño del archivo máximo para este tipo de archivo es 100 MB'))
              return
            }
            if (error.response.status === 400) {
              EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.message))
            }
            EventBus.$emit('showAlert', 'danger', error.response.data.file[0])
          },
        )
        .finally(() => {
          EventBus.$emit('showLoading', false)
        })
      }
    },
    clearInputFile () {
      this.$refs.uploader.value = null
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
  },
  watch: {
    multimediaDirectories: {
      handler: function () {
        if (this.multimediaDirectories.uploadFile.show) {
          this.open()
        }
      },
      deep: true,
    },
  },
}
