import { Service } from './service'

const PATH = 'urls'

class UsersUrlsService extends Service {
  getChannelDatatable (params = {}) {
    return this.get(PATH + '', params)
  }

  getFormAvailableUrls (params = {}) {
    return this.get(PATH + '/get-form-available-urls', params)
  }

  postDataForm (params = {}) {
    return this.post(PATH + '/create', params)
  }

  updateDataForm (params = {}) {
    return this.post(PATH + '/update', params)
  }

  deleteDataForm (params = {}) {
    return this.post(PATH + '/delete', params)
  }

  renameDataForm (params = {}) {
    return this.post(PATH + '/update', params)
  }

  getUrlById (id) {
    return this.get(PATH + '/get-url/' + id)
  }
}

export default new UsersUrlsService()
