import { Service } from './service'

const PATH = 'multimedia-managers'

class MultimediaService extends Service {
  getAll (params = {}) {
    return this.get(PATH + '/all', params)
  }

  search (params = {}) {
    return this.get(PATH + '/search', params)
  }

  uploadFile (params = {}) {
    return this.post(PATH + '/upload-file', params)
  }

  uploadFileAudio (params = {}) {
    return this.post(PATH + '/upload-file-audio', params)
  }

  deletedFile (params = {}) {
    return this.post(PATH + '/deleted-file', params)
  }

  selectedFile (params = {}) {
    return this.post(PATH + '/select-file', params)
  }

  moveFile (params = {}) {
    return this.post(PATH + '/move-item', params)
  }

  moveMultipleFile (params = {}) {
    return this.post(PATH + '/move-multiple-item', params)
  }

  renameItem (params = {}) {
    return this.post(PATH + '/rename-item', params)
  }

  createDirectory (params = {}) {
    return this.post(PATH + '/create-directory', params)
  }

  deletedDirectory (params = {}) {
    return this.post(PATH + '/deleted-directory', params)
  }

  deletedTrash () {
    return this.get(PATH + '/deleted-trash')
  }

  getMp3FromAudio (params = {}) {
    return this.post(PATH + '/audio-to-mp3', params)
  }

  pixaBaySearch (params = {}) {
    return this.post(PATH + '/pixabay-search', params)
  }

  storeSelectedImages (params = {}) {
    return this.post(PATH + '/pixabay-store', params)
  }

  getSizeOfSelection (params = {}) {
    return this.post(PATH + '/size', params)
  }
}

export default new MultimediaService()
