import ListDirectory from '../Common/ListDirectory/ListDirectory.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'DirectoryMultimedia',
  components: {
    ListDirectory,
  },
  props: {
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
    fileQuota: { type: Object, require: true },
    currentDirectory: { type: Object, require: true },
    rootDirectories: { type: Object, require: true },
    openGlobal: { type: Boolean, require: true },
    sectionDirectories: { type: Array, require: true },
    sourceParams: { type: Object, required: true },
  },
  computed: {
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
    multimediaDirectoriesList () {
      // // <!-- App Preview Whatsapp -->
      // if (!this.canWhatsapp) {
      //   delete this.multimediaDirectories.directories.whatsapp
      // }
      console.log(this.multimediaDirectories.directories)

      const directories = {}

      Object.keys(this.multimediaDirectories.directories).forEach(directoryKeyName => {
        if (this.$permissions.hasPermission('view-templates') && ['landings', 'voz', 'formularios', 'whatsapp'].includes(directoryKeyName)) {
          directories[directoryKeyName] = this.multimediaDirectories.directories[directoryKeyName]
        }

        if (this.$permissions.hasPermission('view-files') && ['imagenes', 'csv', 'audios', 'videos', 'adjuntos', 'papelera'].includes(directoryKeyName)) {
          directories[directoryKeyName] = this.multimediaDirectories.directories[directoryKeyName]
        }
      })
      console.log(directories)
      return directories
    },
  },
  methods: {
    clearDirectory () {
      this.sourceParams.source = 'library'
      this.multimediaPreview.show = false
      EventBus.$emit('MultimediaManager:setEmptySearch')
    },
  },
}
