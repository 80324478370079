import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { FadeTransition } from 'vue2-transitions'
import AppBar from '@/components/Layout/AppBar/AppBar.vue'
import MultimediaManager from '@/components/MultimediaManager/MultimediaManager.vue'
import Alert from '@/components/Alert/Alert.vue'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    PerfectScrollbar(`.${className}`)
  } else {
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  name: 'PublicLayout',
  components: {
    FadeTransition,
    AppBar,
    MultimediaManager,
    Alert,
  },
  data () {
    return {
      drawer: null,
      auth: JSON.parse(localStorage.getItem('user')),
    }
  },
  methods: {
    initScrollbar () {
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    },
  },
  mounted () {
    this.initScrollbar()
  },
}
