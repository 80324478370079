export class MenuOptionType {
    static ADD_TO_BLACK_LIST = 'AddToBlackList'
    static ADD_VALUE = 'AddValue'
    static ANSWER = 'Answer'
    static CALL_TRANSFER = 'CallTransfer'
    static END_CALL = 'EndCall'
    static GET_DATA_WHILE_PLAYING = 'GetDataWhilePlaying'
    static NO_VALID_ACTION = 'NoValidAction'
    static PLAY = 'Play'
    static REPEAT_MESSAGE = 'RepeatMessage'
    static SYNTHESIZE_AWS = 'SynthesizeAWS'
    static SYNTHESIZE_REGULAR_SPEECH = 'SynthesizeRegularSpeech'
    static PLAY_AND_SEND_SMS = 'PlayAndSendSms'
}

export class MenuAction {
  action
  text
  icon
  constructor (action, text, icon) {
    this.action = action
    this.text = text
    this.icon = icon
  }
}
