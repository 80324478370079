import moment from 'moment'
import momenttz from 'moment-timezone'
import getSymbolFromCurrency from 'currency-symbol-map'

const formatDate = function (date, format = 'YYYY-MM-DD') {
  if (!date) {
    return
  }
  return moment(date).format(format)
}

const formatDateYearEnd = function (date, format = 'DD/MM/YYYY') {
  if (!date) {
    return
  }
  return moment(date).format(format)
}

const formatDateLocal = function (date, format = 'DD/MM/YYYY HH:mm', timezone = 'Europe/Madrid') {
  if (!date) {
    return
  }
  const d = momenttz.tz(date, timezone).format()
  return moment(d).local().format(format)
}

const lastModified = function (date, format = 'YYYY-MM-DD kk:mm:ss') {
  const localDate = moment.utc(date).toDate()

  return moment(localDate).local().format(format)
}

function getSeparator (locale, separatorType) {
  const numberWithGroupAndDecimalSeparator = 10000.1
  return Intl.NumberFormat(locale)
      .formatToParts(numberWithGroupAndDecimalSeparator)
      .find(part => part.type === separatorType)
      .value
}

// eslint-disable-next-line complexity
const currency = function (amount, decimalCount = 2, decimal = null, thousands = null) {
  const locale = localStorage.getItem('language') || 'es'
  thousands = thousands || getSeparator(locale, 'group')
  decimal = decimal || getSeparator(locale, 'decimal')
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  amount = parseFloat(('' + amount).replace(',', '.'))
  const negativeSign = amount < 0 ? '-' : ''

  const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
  const j = (i.length > 3) ? i.length % 3 : 0

  return negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
}

const currencySymbol = function (code = false) {
  if (!code) {
    code = JSON.parse(localStorage.getItem('user'))?.currencyCode ?? 'EUR'
  }

  if (code === 'COP') {
    return getSymbolFromCurrency(code) + ' ' + code
  }

  return getSymbolFromCurrency(code)
}

const formatMoneyCurrentCurrency = function (amount, decimals = 5, code = false) {
  const symbol = currencySymbol(code)
  if (symbol === '€') {
    return currency(amount, decimals) + ' ' + symbol
  }
  return currency(amount, decimals, '.') + ' ' + symbol
}

const shortChars = function (text, quantity) {
  if (text.length > quantity) {
    return text.substring(0, quantity) + '...'
  }
  return text
}

const shortDecimal = function (data, decimals) {
  const parsedNumber = parseFloat(data)
  if (!Number.isInteger(parsedNumber)) {
    return parsedNumber.toFixed(decimals)
  }
  return parsedNumber
}

const formatBytes = function (bytes) {
  const marker = 1024 // Change to 1000 if required
  const decimal = 0 // Change as required
  const kiloBytes = marker // One Kilobyte is 1024 bytes
  const megaBytes = marker * marker // One MB is 1024 KB
  const gigaBytes = marker * marker * marker // One GB is 1024 MB

  if (bytes < kiloBytes) return bytes + ' Bytes'

  else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + ' KB'

  else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + ' MB'

  else return (bytes / gigaBytes).toFixed(decimal) + ' GB'
}

const truncate = function (text, length, clamp) {
  clamp = clamp || '...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}

const capitalize = function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const reduceDecimals = function (number) {
  if (number === 0) {
    return '0,00'
  }
  const numberStr = number.toString()
  const decimalPoint = numberStr.indexOf('.')
  const resultNumber = decimalPoint !== -1 ? numberStr.substring(0, decimalPoint + 3) : numberStr
  return resultNumber.replace('.', ',')
}

export { formatDate, formatDateYearEnd, formatDateLocal, lastModified, currency, currencySymbol, formatBytes, formatMoneyCurrentCurrency, shortChars, shortDecimal, truncate, capitalize, reduceDecimals }
