export class ErrorMessages {
  i18n = null

  constructor (i18n) {
    this.i18n = i18n
  }

  // eslint-disable-next-line complexity
  getValidationMessage (error, field, values) {
    let message = ''
    switch (error) {
      case 'accepted':
        message = this.i18n.t('errors.accepted', { field: field }).toString()
        break
      case 'activeUrl':
        message = this.i18n.t('errors.activeUrl', { field: field }).toString()
        break
      case 'after':
        message = this.i18n.t('errors.after', { field: field, value: values[0] }).toString()
        break
      case 'afterOrEqual':
        message = this.i18n.t('errors.afterOrEqual', { field: field, value: values[0] }).toString()
        break
      case 'alpha':
        message = this.i18n.t('errors.alpha', { field: field }).toString()
        break
      case 'alphaDash':
        message = this.i18n.t('errors.alphaDash', { field: field }).toString()
        break
      case 'alphaNum':
        message = this.i18n.t('errors.alphaNum', { field: field }).toString()
        break
      case 'array':
        message = this.i18n.t('errors.array', { field: field }).toString()
        break
      case 'before':
        message = this.i18n.t('errors.before', { field: field, value: values[0] }).toString()
        break
      case 'beforeOrEqual':
        message = this.i18n.t('errors.beforeOrEqual', { field: field, value: values[0] }).toString()
        break
      case 'between':
        message = this.i18n.t('errors.between', { field: field, min: values[0], max: values[0] }).toString()
        break
      case 'boolean':
        message = this.i18n.t('errors.boolean', { field: field }).toString()
        break
      case 'confirmed':
        message = this.i18n.t('errors.confirmed', { field: field }).toString()
        break
      case 'date':
        message = this.i18n.t('errors.date', { field: field }).toString()
        break
      case 'dateEquals':
        message = this.i18n.t('errors.dateEquals', { field: field }).toString()
        break
      case 'dateFormat':
        message = this.i18n.t('errors.dateFormat', { field: field }).toString()
        break
      case 'different':
        message = this.i18n.t('errors.different', { field: field, field2: values[0] }).toString()
        break
      case 'digits':
        message = this.i18n.t('errors.digits', { field: field }).toString()
        break
      case 'digitsBetween':
        message = this.i18n.t('errors.digitsBetween', { field: field, min: values[0], max: values[0] }).toString()
        break
      case 'dimensions':
        message = this.i18n.t('errors.dimensions', { field: field }).toString()
        break
      case 'distinct':
        message = this.i18n.t('errors.distinct', { field: field }).toString()
        break
      case 'email':
        message = this.i18n.t('errors.email', { field: field }).toString()
        break
      case 'endsWith':
        message = this.i18n.t('errors.endsWith', { field: field, values: values.join() }).toString()
        break
      case 'exists':
        message = this.i18n.t('errors.exists', { field: field }).toString()
        break
      case 'file':
        message = this.i18n.t('errors.file', { field: field }).toString()
        break
      case 'filled':
        message = this.i18n.t('errors.filled', { field: field }).toString()
        break
      case 'gt':
        message = this.i18n.t('errors.gt', { field: field, value: values[0] }).toString()
        break
      case 'gte':
        message = this.i18n.t('errors.gte', { field: field, value: values[0] }).toString()
        break
      case 'image':
        message = this.i18n.t('errors.image', { field: field }).toString()
        break
      case 'in':
        message = this.i18n.t('errors.in', { field: field }).toString()
        break
      case 'inArray':
        message = this.i18n.t('errors.inArray', { field: field, values: values.join() }).toString()
        break
      case 'integer':
        message = this.i18n.t('errors.integer', { field: field }).toString()
        break
      case 'ip':
        message = this.i18n.t('errors.ip', { field: field }).toString()
        break
      case 'ipv4':
        message = this.i18n.t('errors.ipv4', { field: field }).toString()
        break
      case 'ipv6':
        message = this.i18n.t('errors.ipv6', { field: field }).toString()
        break
      case 'json':
        message = this.i18n.t('errors.json', { field: field }).toString()
        break
      case 'lt':
        message = this.i18n.t('errors.lt', { field: field, value: values[0] }).toString()
        break
      case 'lte':
        message = this.i18n.t('errors.lte', { field: field, value: values[0] }).toString()
        break
      case 'max':
        message = this.i18n.t('errors.max', { field: field, value: values[0] }).toString()
        break
      case 'mimes':
        message = this.i18n.t('errors.mimes', { field: field, values: values.join() }).toString()
        break
      case 'mimetypes':
        message = this.i18n.t('errors.mimetypes', { field: field, values: values.join() }).toString()
        break
      case 'min':
        message = this.i18n.t('errors.min', { field: field, value: values[0] }).toString()
        break
      case 'multipleOf':
        message = this.i18n.t('errors.multipleOf', { field: field, value: values[0] }).toString()
        break
      case 'notIn':
        message = this.i18n.t('errors.notIn', { field: field }).toString()
        break
      case 'notRegex':
        message = this.i18n.t('errors.notRegex', { field: field }).toString()
        break
      case 'numeric':
        message = this.i18n.t('errors.numeric', { field: this.i18n.t(field) }).toString()
        break
      case 'password':
        message = this.i18n.t('errors.password', { field: field }).toString()
        break
      case 'present':
        message = this.i18n.t('errors.present', { field: field }).toString()
        break
      case 'regex':
        message = this.i18n.t('errors.regex', { field: field }).toString()
        break
      case 'required':
        message = this.i18n.t('errors.required', { field: field }).toString()
        break
      case 'requiredIf':
        message = this.i18n.t('errors.requiredIf', { field: field, field2: values[0], value: values[1] }).toString()
        break
      case 'requiredUnless':
        message = this.i18n.t('errors.requiredUnless', { field: field }).toString()
        break
      case 'requiredWith':
        message = this.i18n.t('errors.requiredWith', { field: field, values: values.join() }).toString()
        break
      case 'requiredWithAll':
        message = this.i18n.t('errors.requiredWithAll', { field: field, values: values.join() }).toString()
        break
      case 'requiredWithout':
        message = this.i18n.t('errors.requiredWithout', { field: field, values: values.join() }).toString()
        break
      case 'requiredWithoutAll':
        message = this.i18n.t('errors.requiredWithoutAll', { field: field, values: values.join() }).toString()
        break
      case 'same':
        message = this.i18n.t('errors.same', { field: field, field2: values[0] }).toString()
        break
      case 'size':
        message = this.i18n.t('errors.size', { field: field, value: values[0] }).toString()
        break
      case 'startsWith':
        message = this.i18n.t('errors.startsWith', { field: field, values: values.join() }).toString()
        break
      case 'string':
        message = this.i18n.t('errors.string', { field: field }).toString()
        break
      case 'timezone':
        message = this.i18n.t('errors.timezone', { field: field }).toString()
        break
      case 'unique':
        message = this.i18n.t('errors.unique', { field: field }).toString()
        break
      case 'uploaded':
        message = this.i18n.t('errors.uploaded', { field: field }).toString()
        break
      case 'url':
        message = this.i18n.t('errors.url', { field: field }).toString()
        break
      case 'uuid':
        message = this.i18n.t('errors.uuid', { field: field }).toString()
        break
      case 'logo':
        message = this.i18n.t('errors.logo', { field: field }).toString()
        break
      case 'tooManyHours':
        message = this.i18n.t('errors.tooManyHours').toString()
        break
      default:
        message = this.i18n.t('errors.default', { field: this.i18n.t(field) }).toString()
    }
    return message
  }

  // eslint-disable-next-line complexity
  getMessage (error, value) {
    let message = ''
    switch (error) {
      // login
      case 'login.credentialsError':
        message = this.i18n.t('Usuario o contraseña inválidos').toString()
        break
      case 'login.invalidUser':
        message = this.i18n.t('Usuario inválido').toString()
        break
      case 'login.manyAttempts':
        message = this.i18n.t('Has superado el limite de intentos').toString()
        break
      case 'login.twoWayRequired':
        message = this.i18n.t('Two way code requerido').toString()
        break
      case 'login.twoWayInvalid':
        message = this.i18n.t('Two way code inválido').toString()
        break
      // account
      case 'account.userNotExist':
        message = this.i18n.t('El usuario no existe').toString()
        break
      case 'account.balanceIsNotNumeric':
        message = this.i18n.t('El umbral de saldo debe ser numérico').toString()
        break
      // general
      case 'general.applicationError':
        message = this.i18n.t('Tenemos problemas técnicos en este momento, por favor inténtelo de nuevo más tarde').toString()
        break
      case 'general.resourceNotFound':
        message = this.i18n.t('El recurso solicitado no está disponible').toString()
        break
      // campaigns sendings
      case 'campaignSending.NotExist':
        message = this.i18n.t('El envío no existe').toString()
        break
      case 'campaignSending.unauthorized':
        message = this.i18n.t('No estás autorizado para ver este recurso').toString()
        break
      // sms - voice parser
      case 'parser.invalidPrimaryColumn':
        message = this.i18n.t('No se ha encontrado un dato válido para la columna principal').toString()
        break
      case 'parser.invalidUniqueId':
        message = this.i18n.t('El identificador único está vacío').toString()
        break
      case 'parser.duplicateUniqueId':
        message = this.i18n.t('El identificador único {field} está duplicado', { field: value }).toString()
        break
      case 'parser.duplicatePhone':
        message = this.i18n.t('El dato {field} de la columna principal está duplicado', { field: value }).toString()
        break
      case 'parser.blackListPhone':
        message = this.i18n.t('El dato {field} de la columna principal se encuentra dado de baja', { field: value }).toString()
        break
      case 'parser.invalidCoding':
        message = this.i18n.t('La codificación de la variable no se reconoce').toString()
        break
      case 'parser.invalidVariable':
        message = this.i18n.t('Hay variables en el mensaje que no se reconocen').toString()
        break
      case 'parser.emptyMessage':
        message = this.i18n.t('El mensaje está vacío').toString()
        break
      case 'smsParser.maxParts':
        message = this.i18n.t('El tamaño del mensaje excede el máximo permitido').toString()
        break
      case 'voiceParser.invalidNumVars':
        message = this.i18n.t('Número de variables incorrecto', { field: value }).toString()
        break
      // mail parser
      case 'mailParser.invalidPrimaryColumn':
        message = this.i18n.t('No se ha encontrado un email válido').toString()
        break
      case 'mailParser.duplicateUniqueId':
        message = this.i18n.t('El identificador único {field} está duplicado', { field: value }).toString()
        break
      case 'mailParser.duplicateEmail':
        message = this.i18n.t('El dato {field} de la columna principal está duplicado', { field: value }).toString()
        break
      case 'mailParser.blackListPhone':
        message = this.i18n.t('El email {field} se encuentra dado de baja', { field: value }).toString()
        break
      case 'rcsParser.maxLength':
        message = this.i18n.t('El tamaño del mensaje excede el máximo permitido').toString()
        break
    }
    return message
  }
}
