import axios from 'axios'

class DownloadObject {
    download (url, name) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.setAttribute('download', name)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
}

export default new DownloadObject()
