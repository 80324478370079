import es from 'vee-validate/dist/locale/es.json'
import en from 'vee-validate/dist/locale/en.json'
import fr from 'vee-validate/dist/locale/fr.json'
import it from 'vee-validate/dist/locale/it.json'
import pt from 'vee-validate/dist/locale/pt_PT.json'
import ro from 'vee-validate/dist/locale/ro.json'
import { localize } from 'vee-validate'

export class ValidatorLocalize {
  static setLanguage (langIsoCode) {
    switch (langIsoCode) {
      case 'es':
        localize(langIsoCode, es)
        break
      case 'en':
        localize(langIsoCode, en)
        break
      case 'fr':
        localize(langIsoCode, fr)
        break
      case 'it':
        localize(langIsoCode, it)
        break
      case 'pt':
        localize(langIsoCode, pt)
        break
      case 'ro':
        localize(langIsoCode, ro)
        break
    }
  }
}
