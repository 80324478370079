export class Attachment {
  id = null
  url = null

  constructor (data = null) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data) {
    this.id = data.id
    this.url = data.url
  }
}
