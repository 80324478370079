import VideoPlayer from '../../models/VideoPlayer.js'

// initial state
const state = () => ({
  show: false,
  url: '',
  title: '',
  items: new VideoPlayer(),
})

// getters
const getters = {}

// actions
const actions = {
  toggle ({ commit }, data) {
    commit('setVideo', data)
  },
}

// mutations
const mutations = {
  setVideo (state, data) {
    state.show = data.show
    state.url = data.url
    state.title = data.title
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
