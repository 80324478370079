import EventBus from '../util/EventBus'
export class RatingAppLauncher {
  static checkRateLauncher () {
    const allowedTimesVisited = 25
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && Object.keys(user).includes('showRating') && user.showRating === false) {
       return
    }

    const actualDate = new Date()
    const dateFormatted = actualDate.getFullYear() + '-' + (actualDate.getMonth() + 1) + '-' + actualDate.getDate()
    const timesVisitedHome = localStorage.getItem('timesVisitedHome') ? JSON.parse(localStorage.getItem('timesVisitedHome')) : {}

    if (Object.keys(timesVisitedHome).includes(dateFormatted)) {
      timesVisitedHome[dateFormatted] = parseInt(timesVisitedHome[dateFormatted]) + 1
    } else {
      timesVisitedHome[dateFormatted] = 1
    }

    const totalVisits = RatingAppLauncher.getTotalCount(timesVisitedHome)
    if (totalVisits > allowedTimesVisited) {
      setTimeout(() => {
        EventBus.$emit('rateApp')
      }, 1000)
    } else {
      localStorage.setItem('timesVisitedHome', JSON.stringify(timesVisitedHome))
    }
  }

  static refreshCounter () {
    localStorage.removeItem('timesVisitedHome')
  }

  static getTotalCount (timesVisitedHome) {
    let totalVisits = 0
    Object.keys(timesVisitedHome).forEach(element => {
      totalVisits = totalVisits + timesVisitedHome[element]
    })
    return totalVisits
  }
}
