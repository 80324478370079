export default {
  props: ['menuOption', 'menuAction', 'isPreview'],
  name: 'MenuAction',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    setAction (action) {
      if (this.isPreview === false || this.isPreview === undefined) {
        this.$emit('set-action', action)
      }
    },
  },
  created () {
  },
  mounted () {
  },
}
