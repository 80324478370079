import { Service } from './service'

const PATH = 'template/whatsapp/'


class WhatsappTemplateService extends Service {
  getCategories (params = {}) {
    return this.get(PATH + 'categories', params)
  }

  getStatuses (params = {}) {
    return this.get(PATH + 'statuses', params)
  }

  getLanguages (params = {}) {
    return this.get(PATH + 'languages', params)
  }

  getTemplate (id, params = {}) {
    return this.get(PATH + 'show/' + id, params)
  }

  create (params = {}) {
    return this.post(PATH + 'create', params)
  }

  save (params = {}) {
    return this.post(PATH + 'save', params)
  }

  currentUserhasTemplates () {
    return this.get(PATH + 'has-templates')
  }

  getValidTemplates (params) {
    return this.get(PATH + 'valid-templates', params)
  }

  isBeingUsed (id, params = {}) {
    return this.get(PATH + 'is-being-used/' + id, params)
  }
}

export default new WhatsappTemplateService()

