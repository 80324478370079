import { Service } from './service'

const PATH = 'campaign/voice/'

class CampaignVoiceService extends Service {
  getCallersByGroups (params = {}) {
    return this.post(PATH + 'getCallersByGroups', params)
  }

  getVoiceCreateData (params = {}) {
    return this.get(PATH + 'create', params)
  }

  persist (urlSuffix, params = {}) {
    return this.post(PATH + urlSuffix, params)
  }

  getEditCampaignData (campaignId) {
    return this.get(PATH + 'edit/' + campaignId, {})
  }

  getCreateTemplateData (params = {}) {
    return this.get('template/voice/create', params)
  }

  saveTemplate (params = {}) {
    return this.post('template/voice/save', params)
  }

  getTemplatePreview (params = {}) {
    return this.post('template/voice/preview', params)
  }

  getCallersByIsoCodeAndCurrentUser (params = {}) {
    return this.post(PATH + 'get-callers-by-iso-code', params)
  }
}

export default new CampaignVoiceService()
