import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './modules/auth.js'
import logo from './modules/logo.js'
import balance from './modules/balance.js'
import videoPlayer from './modules/video-player.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    logo,
    balance,
    videoPlayer,
  },
})
