import { TypeTabs } from '../enums/TypeTabs.js'

export class Landing {
  id
  name
  structure
  path
  htmlContent = ''
  type = 0
  isLocalEditMode = false;
  customVars = {};
  editorHelper;
  defaultPath = 'landings';
  defaultExtension = 'svg'


  constructor (structure, id, name, path, htmlContent = '', type = 0) {
    this.id = (id) || -1
    this.name = (name) || ''
    this.path = path
    this.htmlContent = htmlContent
    this.type = type

    if (typeof (structure) === 'string') {
      this.structure = JSON.parse(structure)
    } else {
      this.structure = (structure) || this.getDefaultStructure()
    }

    this.setEditorHelper()
  }

  setEditorHelper () {
    this.editorHelper = {
      tabSelected: TypeTabs.Containers,
      componentSelected: {
        type: null,
        containerIndex: -1,
        slotIndex: -1,
        key: '',
      },
    }
  }

  getDefaultStructure () {
    return {
      backgroundColor: '#f9fafb',
      backgroundPath: '',
      backgroundPathSize: 'cover',
      backgroundPathPosition: 'center center',
      backgroundType: 'color',
      containers: [],
    }
  }

  setStructure (structure) {
    return {
      backgroundColor: structure.backgroundColor,
      backgroundPath: structure.backgroundPath,
      backgroundPathSize: structure.backgroundPathSize,
      backgroundPathPosition: structure.backgroundPathPosition,
      backgroundType: structure.backgroundType,
      containers: structure.containers,
    }
  }
}
