import { SmsData } from '@rtorralba/smsparser'
export class CurrentSms {
  encoding
  maxCharacters
  characters
  chunks
  maxCharactersInfo
  hasCustomFields

  constructor (encoding, characters, chunks, maxCharactersInfo, hasCustomFields = false) {
    this.encoding = encoding
    this.maxCharacters = SmsData.getSize(SmsData.encodings[this.encoding]).normal
    this.characters = characters
    this.chunks = chunks
    this.maxCharactersInfo = maxCharactersInfo
    this.hasCustomFields = false
  }

  static makeFromSmsParserResult (encoding, smsParserResult) {
    const currentSms = new CurrentSms(encoding, 0, 1, 0)
    currentSms.maxCharacters = smsParserResult.chunkCharacters * smsParserResult.chunks
    currentSms.characters = smsParserResult.totalCharactersUsed > 0 ? smsParserResult.totalCharactersUsed : 0
    currentSms.chunks = smsParserResult.chunks
    currentSms.maxCharactersInfo = 0
    currentSms.hasCustomFields = false
    return currentSms
  }
}
