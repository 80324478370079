import EventBus from '@/util/EventBus.js'
import downloadObject from '@/util/DownloadObject'
import CurrentDirectory from '@/models/multimedia-manager/CurrentDirectory.js'
import FileQuote from '@/models/multimedia-manager/FileQuote.js'
import MultimediaDirectory from '@/models/multimedia-manager/MultimediaDirectory.js'
import MultimediaPreview from '@/models/multimedia-manager/MultimediaPreview.js'
import MultimediaService from '@/services/multimedia.service.js'
import ContentMultimedia from './ContentMultimedia/ContentMultimedia.vue'
import CreateDirectoryMultimedia from './CreateDirectory/CreateDirectoryMultimedia.vue'
import DeletedDirectoryMultimedia from './DeletedDirectory/DeletedDirectoryMultimedia.vue'
import DeletedFile from './DeleteFile/DeletedFile.vue'
import DeletedTrash from './DeletedTrash/DeletedTrash.vue'
import DeletedSelectedMultipleItem from './DeletedSelectedMultipleItem/DeletedSelectedMultipleItem.vue'
import DirectoryMultimedia from './DirectoryMultimedia/DirectoryMultimedia.vue'
import HeaderMultimedia from './HeaderMultimedia/HeaderMultimedia.vue'
import MoveFile from './MoveFile/MoveFile.vue'
import uploadFile from './UploadFile/UploadFile.vue'
import RenameItem from './RenameItem/RenameItem.vue'
import PreviewMultimedia from './PreviewMultimedia/PreviewMultimedia.vue'
import { WhatsappTemplateStatus } from '@/classes/WhatsappTemplateStatus'

export default {
  name: 'MultimediaManager',
  components: {
    DeletedDirectoryMultimedia,
    DeletedFile,
    DeletedTrash,
    DeletedSelectedMultipleItem,
    CreateDirectoryMultimedia,
    ContentMultimedia,
    DirectoryMultimedia,
    HeaderMultimedia,
    MoveFile,
    uploadFile,
    RenameItem,
    PreviewMultimedia,
  },
  data () {
    return {
      show: false,
      multimediaDirectories: new MultimediaDirectory(),
      fileQuota: new FileQuote(),
      currentDirectory: new CurrentDirectory(),
      openGlobal: false,
      multimediaPreview: new MultimediaPreview(),
      rootDirectories: {
        imagenes: {
          path: 'imagenes',
          icon: 'fas fa-image',
        },
        csv: {
          path: 'csv',
          icon: 'fas fa-file-csv',
        },
        audios: {
          path: 'audios',
          icon: 'fas fa-music',
        },
        voz: {
          path: 'voz',
          icon: 'fas fa-file-audio',
        },
        videos: {
          path: 'videos',
          icon: 'fas fa-video',
        },
        adjuntos: {
          path: 'adjuntos',
          icon: 'fas fa-phone-alt',
        },
        landings: {
          path: 'landings',
          icon: 'fas fa-file',
        },
        whatsapp: {
          path: 'whatsapp',
          icon: 'fab fa-whatsapp-square',
        },
        formularios: {
          path: 'formularios',
          icon: 'fas fa-check-square',
        },
        papelera: {
          path: 'papelera',
          icon: 'fas fa-trash',
        },
      },
      displayPreviewFixed: false,
      breadcrumbs: null,
      sectionDirectories: [],
      origin: 'multimedia',
      pixabayResult: {
        hits: [],
        selected: [],
        previewImage: null,
        simpleHitSelected: null,
        savingResources: false,
      },
      sourceParams: {
        source: 'library',
      },
      readyPreview: true,
    }
  },
  mounted () {
    EventBus.$on('showMultimedia', (currentRootDirectory, openGlobal, sectionDirectories, origin) => {
      this.sectionDirectories = sectionDirectories ?? []
      this.openGlobal = openGlobal
      this.origin = origin
      this.open(currentRootDirectory, openGlobal)
    })
    EventBus.$on('selectFileMultimedia', (item) => {
      if (item.type === 'file' && this.rootDirectory() === 'papelera') {
        return false
      }
      this.multimediaPreview.loadData(item)
      this.activeEditLading()
    })
    EventBus.$on('closeMultimedia', this.close)

    EventBus.$on('MutimeidaManager:hideMultimediaPreview', () => {
        this.readyPreview = false
        setTimeout(() => {
          this.readyPreview = true
        })
    })

    // <!-- App Preview Whatsapp -->
    // this.multimediaDirectories.canWhatsapp = this.canWhatsapp
  },
  beforeDestroy () {
    EventBus.$off('showMultimedia')
    EventBus.$off('selectFileMultimedia')
    EventBus.$off('closeMultimedia', this.close)
  },
  computed: {
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
  },
  watch: {
    currentDirectory: {
      handler: function (currentDirectory) {
        this.getBreadcrumbs(currentDirectory.path)
        if (currentDirectory.path !== 'buscar') {
          this.search = ''
          this.lastSearch = null
        }
      },
      deep: true,
    },
    show: {
      handler: function () {
        const time = !this.show ? 200 : 0
          setTimeout(() => {
            EventBus.$emit('MultimediaShowValue', this.show)
          }, time)
      },
    },
  },
  methods: {
    downloadImages () {
      EventBus.$emit('PixaBay:DownloadImages')
    },
    open (currentRootDirectory) {
      this.getMultimediaDirectories(currentRootDirectory)
    },
    close () {
      this.show = false
      this.multimediaDirectories.reset()
      this.fileQuota.reset()
      this.currentDirectory.reset()
      this.multimediaPreview.reset()
    },
    getMultimediaDirectories (currentRootDirectory) {
      EventBus.$emit('showLoading', true, this.$t('Cargando el gestor multimedia'))
      MultimediaService.getAll({ path: currentRootDirectory }).then(
        (response) => {
          this.show = true
          this.multimediaDirectories.loadData(response.multimediaDirectories)
          this.fileQuota.loadData(response.fileQuota)
          this.selectCurrentDirectory(currentRootDirectory)
          this.getBreadcrumbs(this.currentDirectory.path)
          EventBus.$emit('showLoading', false)
        },
        () => { },
      )
    },
    selectFile () {
      if (['whatsapp'].includes(this.rootDirectory()) || this.multimediaPreview.extension === 'whatsapp') {
        this.selectSenderWhatsapp()
        return
      }

      const params = {
        path: this.multimediaPreview.path,
        filename: this.multimediaPreview.name,
      }
      MultimediaService.selectedFile(params).then(
        (response) => {
          this.close()
          EventBus.$emit(
            'multimediaSelected',
            response.url,
            response.type,
            response.fileId,
            this.multimediaPreview.nameTemplate,
            response.fileInfo,
          )
        },
        () => { },
      )
    },
    selectEditLanding () {
      this.$router.push({ path: '/template/landing/create', query: { path: this.multimediaPreview.path + '/' + this.multimediaPreview.name } })
      this.close()
    },
    selectEditVoiceTemplate () {
      this.$router.push({ path: '/template/voice', query: { path: this.multimediaPreview.path + '/' + this.multimediaPreview.name } })
      this.close()
    },
    selectEditForm () {
      this.$router.push({ path: '/tools/forms/editor', query: { id: this.multimediaPreview.fileId } })
      this.close()
    },
    selectSenderWhatsapp () {
      this.$router.push({ path: '/campaign/whatsapp/create', query: { templateId: this.multimediaPreview.fileId, type: 'basic' } })
      this.close()
    },
    selectTemplateWhatsapp () {
      this.$router.push({ path: '/template/whatsapp/create', query: { templateId: this.multimediaPreview.fileId, type: 'basic' } })
      this.close()
    },
    selectCurrentDirectory (currentPath) {
      if (currentPath === 'papelera') {
        this.multimediaDirectories.hideFixedMultimedia()
      }

      const split = currentPath.split('/')
      let directory = this.multimediaDirectories.directories[split[0]]
      for (let i = 1; i < split.length; i++) {
        directory = directory.directories[split[i]]
      }
      this.multimediaPreview.reset()
      this.currentDirectory.loadData(directory)
      this.multimediaDirectories.resetDeleteSelectedMultiple()
    },
    verticalOrhorizontalContentDirectory (rootDirectory) {
      if (['csv'].indexOf(rootDirectory) >= 0) {
        return 'col-md-12 multimedia__content-horizontal-data'
      } else if (['papelera', 'landings', 'formularios', 'voz'].indexOf(rootDirectory) >= 0) {
        return 'col-md-12 multimedia__content-vertical-data'
      } else {
        return 'col-md-9 multimedia__content-vertical-data'
      }
    },
    verticalOrhorizontalPreviewDirectory (rootDirectory) {
      if (['csv'].indexOf(rootDirectory) >= 0) {
        return 'col-md-12 multimedia__preview-horizontal-container d-block'
      } else if (['papelera', 'buscar', 'landings', 'formularios', 'voz'].indexOf(rootDirectory) >= 0) {
        return 'd-none'
      } else {
        return 'col-md-3 multimedia__preview-vertical-container d-block'
      }
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
    activeSelectedFile () {
      let canSelect = false
      if (!this.openGlobal && this.multimediaPreview.selectFile && this.multimediaPreview.type === 'file') {
        if (
          (['whatsapp'].includes(this.rootDirectory()) || this.multimediaPreview.extension === 'whatsapp') &&
          WhatsappTemplateStatus.APPROVED !== this.multimediaPreview.preview.template_whatsapp_status_id
        ) {
          canSelect = false
        } else {
          canSelect = true
        }
      }

      return canSelect
    },
    activeEditLading () {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        this.rootDirectory() === 'landings' &&
        this.multimediaPreview.type === 'file'
      ) {
        return true
      } else {
        return false
      }
    },
    activeEditVoiceTemplate () {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        this.rootDirectory() === 'voz' &&
        this.multimediaPreview.type === 'file'
      ) {
        return true
      } else {
        return false
      }
    },
    activeEditForm () {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        this.rootDirectory() === 'formularios' &&
        this.multimediaPreview.type === 'file'
      ) {
        return true
      } else {
        return false
      }
    },
    activeSenderWhatsapp () {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        this.rootDirectory() === 'whatsapp' &&
        this.multimediaPreview.type === 'file' &&
        this.multimediaPreview.preview.template_whatsapp_status_id === WhatsappTemplateStatus.APPROVED
      ) {
        return true
      } else {
        return false
      }
    },
    activeEditTemplateWhatsapp () {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        (['whatsapp'].includes(this.rootDirectory()) || this.multimediaPreview.extension === 'whatsapp') &&
        this.multimediaPreview.type === 'file' &&
        ([
          // WhatsappTemplateStatus.PAUSED,
          WhatsappTemplateStatus.DRAFT,
          // WhatsappTemplateStatus.REJECTED,
          WhatsappTemplateStatus.INCORRECT_FORMAT,
        ].includes(this.multimediaPreview.preview.template_whatsapp_status_id))
      ) {
        return true
      } else {
        return false
      }
    },
    canDownload (item) {
      if (this.openGlobal &&
        this.multimediaPreview.selectFile &&
        ['imagenes', 'csv', 'audios', 'adjuntos', 'videos', 'buscar'].includes(this.rootDirectory()) &&
        this.multimediaPreview.type === 'file'
      ) {
        return true
      } else {
        return false
      }
    },
    downloadFile (item) {
      downloadObject.download(this.multimediaPreview.url, this.multimediaPreview.name)
    },
    getBreadcrumbs (currentPath) {
      this.breadcrumbs = this.setBreadcrumbs(currentPath)
    },
    setBreadcrumbs (currentPath) {
      const splitPath = currentPath?.split('/') || []
      const data = []
      let path = ''
      for (const item of splitPath) {
        path = path + item
        data.push({
          text: item,
          disabled: currentPath === path,
          href: path,
        })

        path = path + '/'
      }
      return data
    },
    setRootDirectoryName (name) {
      if (['voz', 'audios', 'adjuntos', 'formularios', 'papelera', 'buscar'].indexOf(name.toLowerCase()) >= 0) {
        return this.$t(this.capitalize(name).replace(/-/g, ' '))
      }
      if (name === 'csv') {
        return name.toUpperCase()
      }
      if (name === 'landings') {
        return 'Landing page'
      }
      if (name === 'imagenes') {
        return this.$t('Imágenes')
      }

      if (name === 'whatsapp') {
        return this.$t('WhatsApp')
      }

      return name
    },
    capitalize (name) {
      return (
        name.charAt(0).toLocaleUpperCase() + name.substr(1).toLowerCase()
      )
    },
  },
}
