export default class CurrenDirectory {
  date = null
  directories = {}
  directoryType = null
  files = []
  path = null
  initialPath = null
  size = null
  show = false

  loadData (data) {
    if (data) {
      this.date = data.date
      this.directories = data.directories
      this.directoryType = data.directoryType
      this.files = data.files
      this.path = data.path
      this.initialPath = data.initialPath
      this.size = data.size
      this.show = true
    }
  }

  reset () {
    this.date = null
    this.directories = {}
    this.directoryType = null
    this.files = []
    this.path = null
    this.initialPath = null
    this.size = null
    this.show = false
  }
}
