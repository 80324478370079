import { Service } from './service'

const PATH = 'campaign/'

class CampaignService extends Service {
  getAll (params = {}) {
    return this.get(PATH + 'all', params)
  }

  checkCampaignName (params = {}) {
    return this.post(PATH + 'checkName', params)
  }

  getCallersByGroups (params = {}) {
    return this.post(PATH + 'voice/getCallersByGroups', params)
  }

  getVoiceCreateData (params = {}) {
    return this.get(PATH + 'voice/create', params)
  }

  getSmsCreateData (type) {
    return this.get(PATH + 'sms/create?type=' + type)
  }

  getWhatsappCreateData (type) {
    return this.get(PATH + 'whatsapp/create?type=' + type)
  }

  getSmsEditCampaignData (campaignId, type) {
    return this.get(PATH + 'sms/edit/' + campaignId + '?type=' + type)
  }

  getWhatsappEditCampaignData (campaignId, type) {
    return this.get(PATH + 'whatsapp/edit/' + campaignId + '?type=' + type)
  }

  getWhatsappCampaignInfo (params) {
    return this.post(PATH + 'whatsapp/info', params)
  }

  persist (params = {}, url) {
    return this.post(PATH + url, params)
  }

  mailingSendingChangeStatusValidating (params = {}) {
    return this.post(PATH + 'mailing/change-status-validating', params)
  }

  smsSendingChangeStatusValidating (params = {}) {
    return this.post(PATH + 'sms/change-status-validating', params)
  }

  whatsappSendingChangeStatus (params = {}) {
    return this.post(PATH + 'whatsapp/change-status', params)
  }

  send (params = {}, url) {
    return this.post(PATH + url, params)
  }

  getMailingCreateData (type) {
    return this.get(PATH + 'mailing/create?type=' + type)
  }

  getMailingEditCampaignData (campaignId, type) {
    return this.get(PATH + 'mailing/edit/' + campaignId + '?type=' + type)
  }

  getCampaignsProgress (params) {
    return this.get(PATH + 'progress', params)
  }

  duplicate (params) {
    return this.post(PATH + 'duplicate', params)
  }

  delete (campaignId) {
    return this.post(PATH + 'delete/' + campaignId)
  }

  changeStatus (params) {
    return this.post(PATH + 'update/status', params)
  }

  getOptOut (params) {
    return this.post(PATH + 'sms/country/optOut', params)
  }

  getScheduled (params = {}) {
    return this.get(PATH + 'scheduled', params)
  }

  updateCampaignMailStatusSave (params = {}) {
    return this.post(PATH + 'mailing/change-status-saved', params)
  }

  getLandingPreviewData (landingData) {
    return this.post(PATH + 'sms/landing-preview-data', landingData)
  }

  getCampaignSendingSmsValidate (params = {}) {
    return this.get(PATH + 'sending-sms-validates', params)
  }

  getCampaignCost (campaignId) {
    return this.get(PATH + 'calculate-cost/' + campaignId)
  }

  getByChannel (params) {
    return this.post(PATH + 'get-by-channel', params)
  }

  getSmsContacts (params) {
    return this.post(PATH + 'sms/get-contacts', params)
  }

  getRcsCreateData (type) {
    return this.get(PATH + 'rcs/create?type=' + type)
  }

  getRcsEditCampaignData (campaignId, type) {
    return this.get(PATH + 'rcs/edit/' + campaignId + '?type=' + type)
  }
}

export default new CampaignService()
