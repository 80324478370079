import EventBus from '@/util/EventBus'
import WhatsappTemplateService from '@/services/whatsapp-template.service.js'

export default {
  name: 'CreateCampaignModal',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      upHere: false,
      upHereTwo: false,
      upHereThree: false,
      upHereFour: false,
      upHereFive: false,
      upHereSix: false,
      createTemplate: false,
      templateCategories: [],
      templateLanguages: [],
      templateWabas: [],
      templateWhatsapp: {
        name: '',
        category: '',
        language: '',
        wabaId: '',
      },
    }
  },
  created () {
    this.getCategories()
    this.getLanguages()
  },
  watch: {
    userRoutes (newRoutes, oldRoutes) {
      if (newRoutes?.length) {
        newRoutes.forEach(route => {
          this.templateWabas.push({
            id: route.waba_id,
            name: route.display_name + ' - ' + route.waba_id,
          })
        })
      }
    },
  },
  computed: {
    translatedTemplateLanguages () {
      return this.templateLanguages.map(l => {
        l.name = this.$t(l.name)
        return l
      })
    },
    show: {
      get () {
        return this.active
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
    categoryHelpMessage: function () {
      const cat = this.templateCategories?.find(c => c.id === this.templateWhatsapp.category)
      if (!cat) return ''
      switch (cat.id) {
        case 1:
          return this.$t('Envía confirmaciones de reserva, actualizaciones de entregas, recordatorios de citas y mucho más.')
        case 2:
          return this.$t('Envía promociones o información sobre tus productos, servicios o negocio.')
        case 3:
          return this.$t('Envía códigos de acceso de un solo uso para verificación de una transacción, recuperación de la cuenta, contraseñas…')
      }
    },
    translatedTemplateCategories () {
      return this.templateCategories.map(c => {
        c.name = this.$t(c.name)
        return c
      })
    },
    userRoutes () {
      return this.data.whatsappRoutes
    },
  },
  methods: {
    close () {
      this.show = false
      setTimeout(() => {
        this.createTemplate = false
      }, 500)
    },
    getCategories () {
      WhatsappTemplateService.getCategories()
        .then(response => {
          this.templateCategories = response.data
        })
    },
    getLanguages () {
      WhatsappTemplateService.getLanguages()
        .then(response => {
          this.templateLanguages = response.data
        })
    },
    async createTemplateWhatsapp () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.$router.push({
        name: 'WhatsappEditor',
        query: {
          name: this.templateWhatsapp.name,
          category: this.templateWhatsapp.category,
          language: this.templateWhatsapp.language,
          wabaId: this.templateWhatsapp.wabaId,
        },
      })
      this.close()

      this.templateWhatsapp = {
        name: '',
        category: '',
        language: '',
        wabaId: '',
      }
    },
    preCreateSenderWhatsapp () {
      WhatsappTemplateService.currentUserhasTemplates().then((response) => {
        if (response.hasTemplates) {
          this.createSenderWhatsapp()
        } else {
          this.createTemplate = true
        }
      })
    },
    createSenderWhatsapp () {
      EventBus.$emit('showMultimedia', 'whatsapp', true)
      this.close()
    },
    formatSnakeCase () {
      const string = this.templateWhatsapp.name.toLowerCase()
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .join('_')
      this.templateWhatsapp.name = string
    },
  },
}
