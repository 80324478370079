import { MenuOptionData } from './MenuOptionData'
import { MenuOptionType } from './MenuOptionType'
import { Menu } from './Menu'

/* eslint-disable complexity */
export class MenuOption {
  key = undefined
  typeId = undefined
  data = new MenuOptionData()
  menu
  phoneKey
  gender
  language
  goToSection = null
  description = ''

  constructor (data = null) {
    if (data) {
      this.data = data.data ? new MenuOptionData(data.data) : this.data
      this.key = data.key
      this.typeId = data.typeId
      this.menu = data.menu ? new Menu(data.menu) : this.menu
      this.goToSection = data.goToSection ? data.goToSection : this.goToSection
      this.description = data.description ? data.description : ''
    }
  }

  setGetDataWhilePlayingMenuData () {
    if (this.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
      this.menu.message = this.data.message
      this.menu.url = this.data.url
      this.menu.type = this.data.type
      this.menu.voiceSelected = this.data.voiceSelected
      this.menu.language = this.data.language
      this.menu.gender = this.data.gender
    }
  }

  hasKey () {
    return this.key !== undefined
  }

  hasTypeId () {
    return this.typeId !== undefined
  }

  setData (data) {
    if (data) {
      this.key = data.key
      this.typeId = data.typeId
      this.data = data.data ? new MenuOptionData(data.data) : undefined
      this.goToSection = data.goToSection ? data.goToSection : this.goToSection
      this.description = data.description ? data.description : ''
    }
  }

  setCommonData (data) {
    if (!data) { return }
    this.phoneKey = data.phoneKey
    this.gender = data.gender
    this.language = data.language
  }

  isValidPhoneKeyAndType () {
    if (this.key === null) {
      return false
    }

    if (this.typeId === undefined) {
      return false
    }
    return true
  };

  // eslint-disable-next-line max-lines-per-function
  isValid () {
    let isValid = false
    switch (this.typeId) {
      case MenuOptionType.GET_DATA_WHILE_PLAYING:
        isValid = this.goToSection !== null
        break

      case MenuOptionType.CALL_TRANSFER:
        isValid = this.data.isValidPhone() && this.data.isValidLocutionWithNoMessage() && this.data.isValidPhonePrefix()
        break

      case MenuOptionType.ADD_TO_BLACK_LIST:
        isValid = this.data.isValidLocutionWithNoMessage()
        break

      case MenuOptionType.REPEAT_MESSAGE:
        isValid = true
        break

      case MenuOptionType.PLAY_AND_SEND_SMS:
        isValid = this.data.isValidSpeechOrAudio() && this.data.isValidSmsData()
        break

      case MenuOptionType.PLAY:
        isValid = this.data.isValidSpeechOrAudio()
        break
    }
    return this.isValidPhoneKeyAndType() && isValid
  }

  cleanData () {
    if (this.typeId !== MenuOptionType.CALL_TRANSFER && this.typeId !== MenuOptionType.REPEAT_MESSAGE) {
      this.data.cleanPhone()
    }

    if (this.typeId === MenuOptionType.REPEAT_MESSAGE) {
      this.data = undefined
    } else {
      this.data.cleanByType()
    }
  }

  cleanErrorOrOmission () {
    if (this.typeId === MenuOptionType.PLAY) {
      this.data.cleanNovalidOptionData()
    }

    if (this.typeId === MenuOptionType.CALL_TRANSFER) {
      this.data.cleanCallTransferData()
    }
  }

  copy = function () {
    return { ...this }
  }

  static getErrorOption () {
    const errorOption = new MenuOption()
    errorOption.key = -1
    return errorOption
  }

  static getErrorOptionPlay () {
    const errorOption = new MenuOption()
    errorOption.key = -1
    errorOption.typeId = MenuOptionType.PLAY
    return errorOption
  }

  static getErrorOptionCallTransfer () {
    const errorOption = new MenuOption()
    errorOption.key = -1
    errorOption.typeId = MenuOptionType.CALL_TRANSFER
    return errorOption
  }

  static getIconClass (typeId) {
    switch (typeId) {
      case 'AddToBlackList':
        return 'fas fa-user-slash'
      case 'CallTransfer':
        return 'fa fa-phone-volume'
      case 'GetDataWhilePlaying':
        return 'fas fa-random'
      case 'Play':
        return 'fas fa-volume-up'
      case 'RepeatMessage':
        return 'fas fa-redo'
      case 'PlayAndSendSms':
        return 'fas fa-sms'
    }
  }
}
