import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'
import $t from '../util/Translator'

export class ValidationRules {
  rules = null
  extend = null
  constructor () {
    this.rules = rules
    this.extend = extend
  }

  // eslint-disable-next-line max-lines-per-function
  extendRules () {
    const extend = this.extend
    const rules = this.rules
    Object.keys(this.rules).forEach(rule => {
      extend(rule, rules[rule])
    })

    extend('max_sender', {
      ...this.rules.max,
      message: $t('El campo debe contener hasta 11 caracteres'),
    })

    extend('limit_phones', {
      validate (value, { max }) {
        max = parseInt(max)
        if (value >= 1 && value <= max) {
          return true
        }
        return false
      },
      params: ['max'],
    })

    extend('greater_or_equal_than', {
      validate (value, { min }) {
        min = parseInt(min)
        if (value >= min) {
          return true
        }
        return false
      },
      params: ['min'],
    })

    extend('is_true', {
      validate (value) {
        return value
      },
    })

    extend('current_date_after', {
      validate (value) {
        const currentDate = moment()
        const inputDate = moment(value)
        return inputDate.isSameOrAfter(currentDate)
      },
      message: $t('La fecha seleccionada debe ser mayor que la actual'),
    })

    extend('current_date_threemonths_after', {
      validate (value) {
        const maxDate = moment().add(3, 'M')
        const inputDate = moment(value)
        console.log(maxDate.format('YYYY-MM-DD HH:mm'), inputDate.format('YYYY-MM-DD HH:mm'), inputDate > maxDate)
        return maxDate >= inputDate
      },
      message: $t('La fecha seleccionada sobrepasa el máximo de 3 meses'),
    })

    extend('url', {
      validate (value) {
        if (value) {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
        }

        return false
      },
      message: $t('Este valor debe ser una URL válida'),
    })

    extend('valid_emails', {
      validate (value) {
        let isValid = true
        const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@]+/
        value.forEach(email => {
          if (!emailRegex.test(email)) {
            isValid = false
          }
        })
        return isValid
      },
      message: $t('Este campo debe contener direcciones de email válidas'),
    })
  }
}
