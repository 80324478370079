import { LocutionType } from './LocutionType'
export class MenuOptionData {
  id
  name = ''
  gender
  language
  message = ''
  type = 'speech'
  url = ''
  originalFileName
  originalFileSize
  durationSeconds
  destAddr
  phone
  phonePrefix
  isoCode = ''
  record
  sender
  textMessage
  encoding = 'GSM'
  voiceSelected = 'premium'
  bookmark = false
  templateId
  templateStructure

  // eslint-disable-next-line max-lines-per-function
  constructor (data = null) {
    this.setData(data)
  }

  // eslint-disable-next-line max-lines-per-function
  setData (data) {
    data = this.parseData(data)
    if (data) {
      this.gender = data.gender
      this.language = data.language
      this.message = data.message
      this.type = data.type
      this.url = data.url
      this.originalFileName = data.originalFileName
      this.originalFileSize = data.originalFileSize
      this.durationSeconds = data.duration_seconds
      this.destAddr = data.destAddr
      this.phone = data.phone
      this.phonePrefix = data.phonePrefix
      this.isoCode = data.isoCode
      this.record = data.record
      this.sender = data.sender
      this.textMessage = data.textMessage
      this.bookmark = data.bookmark
      this.encoding = data.encoding ? data.encoding.toUpperCase() : this.encoding
      this.templateId = data.templateId
      this.templateStructure = data.templateStructure
    }
  }

  setAudioData (data) {
    this.url = data.fileName
    this.originalFileName = data.originalFileName
    this.originalFileSize = data.originalFileSize
    this.durationSeconds = data.durationSeconds
  }

  cleanByType () {
    switch (this.type) {
      case LocutionType.SPEECH_TYPE:
        this.cleanAudio()
        break

      case LocutionType.AUDIO_TYPE:
        this.cleanSpeech()
        break
    }
  }

  cleanPhone () {
    this.phone = undefined
    this.phonePrefix = ''
  }

  cleanAudio () {
    this.url = undefined
    this.originalFileName = undefined
    this.originalFileSize = undefined
    this.durationSeconds = undefined
  }

  cleanSpeech () {
    this.gender = undefined
    this.language = undefined
    this.message = undefined
  }

  cleanNovalidOptionData () {
    this.cleanByType()
    this.cleanPhone()
    this.phonePrefix = undefined
    this.destAddr = undefined
  }

  cleanCallTransferData () {
    this.cleanAudio()
    this.cleanSpeech()
  }

  isLocutionSelected () {
    return this.type !== '' && this.type != null && this.type !== undefined
  }

  isValidSpeech () {
    if (this.type === LocutionType.SPEECH_TYPE && this.message) {
      return true
    }
    return false
  }

  isValidUploadedAudio () {
    if (this.type === LocutionType.AUDIO_TYPE && this.url) {
      return true
    }
    return false
  }

  isValidSpeechOrAudio () {
    return this.isValidSpeech() || this.isValidUploadedAudio()
  }

  isValidPhone () {
    if (this.phone && this.phone !== '' && this.phone !== undefined) {
      return true
    }
    return false
  }

  isValidPhonePrefix () {
    if (this.phonePrefix && this.phonePrefix !== '' && this.phonePrefix !== undefined) {
      return true
    }
    return false
  }

  isValidLocution () {
    return this.isLocutionSelected() && this.isValidSpeechOrAudio()
  }

  isValidLocutionWithNoMessage () {
    return (this.isLocutionSelected() && this.isValidSpeechOrAudio()) || !this.isLocutionSelected()
  }

  isValidSmsData () {
    // eslint-disable-next-line no-magic-numbers
    if (this.sender && this.sender.length <= 11 && this.textMessage) {
      return true
    }
    return false
  }

  parseData (data) {
    data = typeof data === 'string' ? data = JSON.parse(data) : data
    return data
  }

  setDataFromPhonesWithPrefix (phonesWithPrefix) {
    this.phone = phonesWithPrefix.phones[0] ? phonesWithPrefix.phones[0] : ''
    this.destAddr = phonesWithPrefix.phonesWithPrefix[0] ? phonesWithPrefix.phonesWithPrefix[0] : ''
    this.phonePrefix = phonesWithPrefix.prefix
    this.isoCode = phonesWithPrefix.isoCode
  }

  setLanguageAndGenderFromSynthesisOptions (synthesisOptions) {
    this.language = synthesisOptions.language
    this.gender = synthesisOptions.language
  }

  setDataFromSmsObject (smsObject) {
    this.sender = smsObject.sender
    this.encoding = smsObject.encoding
    this.textMessage = smsObject.message
    this.bookmark = smsObject.bookmark
    this.templateId = smsObject.templateId
    this.templateStructure = smsObject.templateStructure
  }
}
