import EventBus from '@/util/EventBus.js'

export default {
  name: 'Alert',
  data () {
    return {
      snackbar: {
        color: '#172b4d',
        visible: false,
        message: this.$t('Tenemos problemas técnicos en este momento') + '. ' + this.$t('Inténtelo de nuevo más tarde'),
        timeout: 5000,
      },
    }
  },
  methods: {
    // eslint-disable-next-line complexity
    show (type, message, timeout) {
      if (!type) return
      switch (type) {
        case 'default':
          this.snackbar = {
            color: '#172b4d',
            visible: true,
            message: message,
            timeout: timeout,
          }
          break
        case 'success':
          this.snackbar = {
            color: '#2dce89',
            visible: true,
            message: message,
            timeout: timeout,
          }
          break
        case 'warning':
          this.snackbar = {
            color: '#fb6340',
            visible: true,
            message: message,
            timeout: timeout,
          }
          break
        case 'danger':
          this.snackbar = {
            color: '#f5365c',
            visible: true,
            message: message,
            timeout: timeout,
          }
          break
        case 'info':
          this.snackbar = {
            color: '#37d5f2',
            visible: true,
            message: message,
            timeout: timeout,
          }
          break
      }
    },
  },
  created () {
    EventBus.$on('showAlert', (type, message, timeout = 5000) => {
      if (typeof (message) !== 'string' || message === '') {
        message = this.snackbar.message
      }
      this.show(type, message, timeout)
    })
    EventBus.$on('hideAlert', () => {
      this.snackbar.visible = false
    })
  },
}
