import EventBus from '@/util/EventBus.js'
import { Country } from '@/util/Country.js'

export default {
    name: 'PricesByCountry',
    props: [
        'countries',
        'sendingCost',
        'certified',
        'currentSmsIn',
        'encoding',
        'totalRecipients',
    ],
    data: function () {
        return {
            show: false,
            currentSms: this.currentSmsIn,
            Country: Country,
        }
    },
    created () {
        const that = this
        EventBus.$on('showModalPrices', function (currentSmsIn) {
            that.currentSms = currentSmsIn
            that.show = true
        })
    },
}
