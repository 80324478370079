import { LocutionType } from './LocutionType'
import { MenuOptionType } from './MenuOptionType'
import { MenuOption } from './MenuOption'
export class Menu {
  id = 1
  name = 'Home'
  gender
  language
  message = ''
  url = ''
  type = LocutionType.SPEECH_TYPE
  voiceSelected = 'premium'
  sections = []
  menuOptions = []
  languageWithGender = ''
  speechRetries = 25
  speechTimeoutSeconds = 5

  constructor (data = null) {
    this.setData(data)
  }

  setData (data) {
    if (data) {
      this.id = data.id
      this.name = data.name ? data.name : 'Home'
      this.gender = data.gender
      this.language = data.language
      this.message = data.message
      this.url = data.url
      this.type = data.type
      this.menuOptions = data.menuOptions ? this.parseMenuOptions(data.menuOptions) : []
      this.languageWithGender = data.languageWithGender ? data.languageWithGender : ''
      this.sections = data.sections ? this.parseSections(data.sections) : this.sections
      this.speechRetries = data.speechRetries
      this.speechTimeoutSeconds = data.speechTimeoutSeconds
    }
  }

  parseSections (sections) {
    return sections.map(function (sectionObj) { return new Menu(sectionObj) })
  }

  static getMainMenu () {
    const menu = new Menu()
    menu.menuOptions.push(MenuOption.getErrorOptionPlay())
    return menu
  }

  cleanByType () {
    switch (this.type) {
      case LocutionType.SPEECH_TYPE:
        this.cleanAudio()
        break

      case LocutionType.AUDIO_TYPE:
        this.cleanSpeech()
        break
    }
  }

  cleanIfIsSpeech () {
    if (this.type === LocutionType.SPEECH_TYPE) {
      this.cleanAudio()
    }
  }

  cleanAudio () {
    this.url = undefined
  }

  cleanSpeech () {
    this.gender = undefined
    this.language = undefined
    this.message = undefined
  }

  setLanguageToMenuOptions () {
    this.menuOptions.forEach(menuOption => {
      if (menuOption.typeId !== MenuOptionType.REPEAT_MESSAGE) {
        menuOption.data.language = this.language
        menuOption.data.gender = this.gender
      }

      if (menuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
        menuOption.menu.language = this.language
        menuOption.menu.gender = this.gender
        menuOption.menu.setLanguageToMenuOptions()
      }
    })
  }

  // eslint-disable-next-line max-lines-per-function
  parseMenuOptions (options) {
    const menuOptions = []
    for (let index = 0; index < options.length; index++) {
      const option = options[index]
      const menuOption = new MenuOption()
      menuOption.setData(option)
      if (menuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
        menuOption.menu = new Menu(option.menu)
        // eslint-disable-next-line max-depth
        if (option.menu.menuOptions.length > 0) {
          menuOption.menu.menuOptions = this.parseMenuOptions(option.menu.menuOptions)
        }
      }
      menuOptions.push(menuOption)
    }
    return menuOptions
  }
}
