import { Service } from './service'

const PATH = 'news'

class NewsService extends Service {
  getNews () {
    return this.get(PATH)
  }

  markAllNewsAsRead () {
    return this.get(PATH + '/mark-as-read')
  }

  getNew (params) {
    return this.post(PATH + '/get-new', params)
  }
}

export default new NewsService()
