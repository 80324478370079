
export class SmsRegExp {
  regex = {
    variablesRegex: /(\{(?!(LAND_URL|UNSUB_URL|UNSUB_URL_\d+|FORM_\d+|ATTACHMENT_\d+|URL_\d+)).*?\})/mg,
    urlRegExp: /\{(LAND_URL|UNSUB_URL|UNSUB_URL_\d+|FORM_\d+|ATTACHMENT_\d+|URL_\d+)\}/mg,
    EXAMPLE_SHORTENED_URL: 'https://nrs.so/sample',
  }

  static getRegExp () {
    const smsRegExp = new SmsRegExp()
    return smsRegExp.regex
  }
}
