import { Service } from './service'
const PATH = 'form-configuration'

class FormConfigurationService extends Service {
  getFields () {
    return this.get(PATH + '/fields')
  }

  getRcsFields () {
    return this.get(PATH + '/rcs-fields')
  }
}

export default new FormConfigurationService()
