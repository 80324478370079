import { Service } from './service'

const PATH = 'subscription-plan/'

class SubscriptionPlanService extends Service {
  getSubscriptionPlans () {
    return this.get(PATH + 'all')
  }

  getSubscriptionPlan (idPlan) {
    return this.get(PATH + 'plan/' + idPlan)
  }

  getSubscribedPlans () {
    return this.get(PATH + 'my-plans')
  }

  isSubscribed (params = {}) {
    return this.get(PATH + 'is-active', params)
  }

  confirmSubscription (params = {}) {
    return this.post(PATH + 'confirm', params)
  }

  updateCreditCardSubscription (params = {}) {
    return this.post(PATH + 'update', params)
  }

  confirmSubscriptionRenew (params = {}) {
    return this.post(PATH + 'renew-confirm', params)
  }

  stopSubscription (params = {}) {
    return this.post(PATH + 'stop', params)
  }

  getRenewalInformation (params = {}) {
    return this.post(PATH + 'renewal-info', params)
  }
}

export default new SubscriptionPlanService()
