export default class HeadboardOption {
  static options = {
    IMAGE: 1,
    TEXT: 2,
    VIDEO: 3,
    DOCUMENT: 4,
    LOCATION: 5,
  }

  static optionsText = {
    IMAGE: 'Imagen',
    TEXT: 'Texto',
    VIDEO: 'Video',
    DOCUMENT: 'Documento',
    LOCATION: 'Localización',
  }

  static fileMaxSize = {
    AUDIO: 100,
    DOCUMENT: 100,
    IMAGE: 5,
    VIDEO: 16,
  }

  static tooBig (sizeInBytes, mb) {
    return HeadboardOption.bytesToMB(sizeInBytes) > mb
  }

  static bytesToMB (sizeInBytes) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2)
  }

  // eslint-disable-next-line complexity
  static fileType (fileInfo) {
    let simpleType = ''
    let tooBig = false
    let sizeTooBig = -1

    // if (['jpg', 'png', 'wepb'].includes(fileInfo.extension)) {
    if ([
      'image/jpeg',
      'image/png',
    ].includes(fileInfo.mimeType)) {
      simpleType = 'image'
      sizeTooBig = HeadboardOption.fileMaxSize.IMAGE
      if (HeadboardOption.tooBig(fileInfo.size, sizeTooBig)) {
        tooBig = true
      }
    }

    // if (['mp4', '3gp'].includes(fileInfo.extension)) {
    if ([
      'video/mp4',
      'video/3gp',
    ].includes(fileInfo.mimeType)) {
      simpleType = 'video'
      sizeTooBig = HeadboardOption.fileMaxSize.VIDEO
      if (HeadboardOption.tooBig(fileInfo.size, sizeTooBig)) {
        tooBig = true
      }
    }

    // if (['mp3', 'aac', 'mpeg', 'amr'].includes(fileInfo.extension)) {
    if ([
      'audio/x-wav',
      'audio/aac',
      'audio/mp4',
      'audio/mpeg',
      'audio/amr',
      'audio/ogg',
    ].includes(fileInfo.mimeType)) {
      simpleType = 'audio'
      sizeTooBig = HeadboardOption.fileMaxSize.AUDIO
      if (this.tooBig(fileInfo.size, sizeTooBig)) {
        tooBig = true
      }
    }

    // if (['txt', 'text', 'pdf', 'ppt', 'doc', 'xls'].includes(fileInfo.extension)) {
    if ([
      'text/plain',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(fileInfo.mimeType)) {
      simpleType = 'document'
      sizeTooBig = HeadboardOption.fileMaxSize.DOCUMENT
      if (this.tooBig(fileInfo.size, sizeTooBig)) {
        tooBig = true
      }
    }

    return [simpleType, tooBig, sizeTooBig]
  }
}

