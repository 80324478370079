import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'CreateDirectoryMultimedia',
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      show: false,
      loading: false,
      itemPath: false,
      item: {
        name: '',
        path: '',
      },
    }
  },
  methods: {
    close () {
      this.resetFormDirectory()
      this.multimediaDirectories.resetCreateDirectory()
    },
    resetFormDirectory () {
      this.$refs.formDirectory.reset()
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observer.reset()
    },
    async createDirectory () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true
      this.item.path = this.multimediaDirectories.createDirectory.path
      MultimediaService.createDirectory(this.item).then(
        (response) => {
          if (response.success === true) {
            this.multimediaPreview.reset()
            this.multimediaDirectories.loadData(response.multimediaDirectories)
            const split = this.currentDirectory.path.split('/')
            let directory = this.multimediaDirectories.directories[split[0]]
            for (let i = 1; i < split.length; i++) {
              directory = directory.directories[split[i]]
            }
            this.currentDirectory.loadData(directory)

            EventBus.$emit('showAlert', 'success', this.$t(response.code))
          } else {
            EventBus.$emit('showAlert', 'danger', this.$t(response.code))
          }
          this.close()
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error)
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
  },
}
