
export class TextFormatParser {
  formatHtmlMessage (message) {
    message = this.setStrikes(message)
    message = this.setItalic(message)
    message = this.setStrong(message)
    message = this.setCode(message)
    message = this.setInlineCode(message)
    message = this.setOrderedList(message)
    message = this.setUnorderedOrderedList(message)
    message = this.setQuotes(message)
    message = this.replaceEscapedQuotes(message)
    return message
  }

  formatHtmlMessageFixHtml (message) {
    message = this.setStrikes(message)
    message = this.setItalic(message)
    message = this.setStrong(message)
    message = this.setCode(message)
    message = this.setInlineCode(message)
    message = this.setOrderedListHtmlFix(message)
    message = this.setUnorderedOrderedListHtmlFix(message)
    message = this.setQuotesFixHtml(message)
    message = this.replaceEscapedQuotes(message)
    return message
  }

  replaceEscapedQuotes (message) {
    const regExp = /\\"/g
    message = message.replace(regExp, '"')
    return message
  }

  setStrikes (message) {
    const regExp = /~([^\s~]+(?: [\w\s]+)*)~/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.slice(1, -1)
        message = message.replace(match, '<strike>' + replacedMatch + '</strike>')
      })
    }
    return message
  }

  setItalic (message) {
    const regExp = /_([^\s_]+(?: [\w\s]+)*)_/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.slice(1, -1)
        message = message.replace(match, '<em>' + replacedMatch + '</em>')
      })
    }
    return message
  }

  setStrong (message) {
    const regExp = /\*[^\s*](?:[^*])*[^\s*]\*/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.slice(1, -1)
        message = message.replace(match, '<strong>' + replacedMatch + '</strong>')
      })
    }
    return message
  }

  setCode (message) {
    // const regExp = /```([^\s*]+(?: [\w\s]+)*)```/gm
    const regExp = /```(?!(?:```)|[\s]+)(?:.+)[^\s]```/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.slice(3, -3)
        message = message.replace(match, '<code class="nocolor">' + replacedMatch + '</code>')
      })
    }
    return message
  }

  setInlineCode (message) {
    const regExp = /`([^\s`]+(?: [\w\s]+)*)`/g
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.slice(1, -1)
        message = message.replace(match, '<code>' + replacedMatch + '</code>')
      })
    }
    return message
  }

  setQuotesFixHtml (message) {
    const regExp = /(?<=^|\n)> .+?(?=<br>|$)/gm

    const lines = message.split('<br>')
    const newLines = []

    lines.forEach((line) => {
      const matches = line.match(regExp)
      if (matches) {
        matches.forEach((match) => {
          const replacedMatch = match.replace('>', '')
          newLines.push(line.replace(match, ' <blockquote class="bloque-quote-whatsapp">' + replacedMatch + '</blockquote>'))
        })
      } else {
        newLines.push(line)
      }
    })
    return newLines.join('<br>')
  }

  setQuotes (message) {
    const regExp = /(?<=^|\n)> .+?(?=\n|$)/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.replace('>', '')
        message = message.replace(match, ' <blockquote class="bloque-quote-whatsapp">' + replacedMatch + '</blockquote>')
      })
    }
    return message
  }


  setOrderedList (message) {
    const regExp = /(?<=^|\n)\d+[.] .+?(?=\n|$)/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        message = message.replace(match, '  ' + match)
      })
    }
    return message
  }

  setOrderedListHtmlFix (message) {
    const regExp = /(?<=^|<br>)\d+[.] .+?(?=<br>|$)/gm
    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        message = message.replace(match, '&nbsp;&nbsp;' + match)
      })
    }
    return message
  }

  setUnorderedOrderedList (message) {
    const regExp = /(?<=^|\n)- .+?(?=\n|$)/gm

    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.replace('-', '')
        message = message.replace(match, '  <div class="list-dots"></div>' + replacedMatch)
      })
    }
    return message
  }

  setUnorderedOrderedListHtmlFix (message) {
    const regExp = /(?<=^|<br>)- .+?(?=<br>|$)/gm

    const matches = message.match(regExp)
    if (matches) {
      matches.forEach((match) => {
        const replacedMatch = match.replace('-', '')
        message = message.replace(match, '&nbsp;&nbsp;<div class="list-dots"></div>' + replacedMatch)
      })
    }
    return message
  }
}
