export class Languages {
  static defaultLanguageIso = 'es'
  static languages = [
    {
      iso: 'es',
      locale: 'es-ES',
      tooltip: 'Español',
    },
    {
      iso: 'en',
      locale: 'en-GB',
      tooltip: 'English',
    },
    {
      iso: 'it',
      locale: 'it-IT',
      tooltip: 'Italiano',
    },
    {
      iso: 'pt',
      locale: 'pt-PT',
      tooltip: 'Português',
    },
    {
      iso: 'fr',
      locale: 'fr-FR',
      tooltip: 'Français',
    },
    {
      iso: 'ro',
      locale: 'ro-RO',
      tooltip: 'Română',
    },
  ]

  static get () {
    return this.languages
  }
}
