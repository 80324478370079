
export class Country {
  static getFlag (country) {
    try {
      return require(`@/assets/img/flags/${country.toLowerCase()}.svg`)
    } catch {
      return require('@/assets/img/flags/not_found.svg')
    }
  }
}
